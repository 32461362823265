<template>
  <div>
    <h3>Intents waiting for Your classification</h3>

    <div class="card shadow mb-4">
      <div class="card-header d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Unrecognized intents</h6>
        <span class="float-right" @click="reloadUncategorizedIntents()">
          <i class="fas fa-sync-alt"></i>
        </span>
      </div>
      <b-alert :show="edited" variant="warning" size="sm" class="text-small">
        <small>NOTE: The model has been updated, but <strong>not retrained</strong>. Click on Train NLU to update model.</small>
      </b-alert>

      <div class="card-body">
        <a href="/docs/unclassified_intents/" target="_blank" class="float-right">
          Documentation
          <i class="fas fa-external-link-alt"></i>
        </a>

        <b-row class="text-gray-600 m-2 p-1 font-weight-bold">
          <b-col cols="6">User's intent</b-col>
          <b-col cols="1" class="text-center">Issued count</b-col>
          <b-col cols="2" class="text-center">Conversations</b-col>
          <b-col cols="3" class="text-center" v-if="checkPermissions('/intents/<id:int>/mark-classified', 'POST')"
            >Select Intent Class</b-col
          >
        </b-row>
        <hr />
        <b-row v-for="(intent, j) in unclassifiedIntents" :key="j" class="py-1" :class="{ 'bg-gray-100': j % 2 }">
          <b-col cols="6">
            <div
              class="text-info mb-0 d-flex flex-wrap align-items-center"
              v-if="checkPermissions('/intent/unclassified/<id:int>', 'POST')"
            >
              <b-container class="pt-1">
                <b-row v-if="!editingIntent || editingIntent.id !== intent.id" @click="editUnclassifiedIntent(intent)">
                  <span>{{ intent.intent }}</span>
                  <i class="fa fa-pencil-alt ml-auto"></i>
                </b-row>
                <b-row v-else>
                  <div class="flex-grow-1 mx-0 px-0">
                    <input
                      v-model="intent.intent"
                      :placeholder="intent.intent"
                      :ref="'edit-intent-' + intent.id"
                      @keypress.enter="editingIntent = null"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <i
                    v-if="editingIntent && editingIntent.id === intent.id"
                    class="fa fa-check my-auto pl-2"
                    @click.prevent="editingIntent = null"
                  ></i>
                </b-row>
              </b-container>
              <div v-if="editingIntent && editingIntent.id === intent.id && suggestion != intent.intent" class="w-100">
                <div @click="clickSuggestion(j)">
                  <b-alert :show="suggestion !== null && suggestion.length != 0" variant="info" class="m-2 p-1">
                    Suggested phrase to add: <b class="link">{{ suggestion }} </b></b-alert
                  >
                </div>
                <b-alert :show="!suggestion && suggestion !== null" variant="warning" class="m-2 p-1"
                  >Warning: phrase containing only stop words</b-alert
                >
              </div>
            </div>
            <p class="text-info mb-1" v-else>
              {{ intent.intent }}
            </p>
          </b-col>
          <b-col cols="1">{{ intent.counter }}</b-col>
          <b-col cols="2">
            <b-dropdown
              v-if="checkPermissions('/conversations/<id:int>', 'GET')"
              id="dropdown-left"
              text="Show conversations"
              variant="outline-secondary"
              size="sm"
              dropleft
            >
              <b-dropdown-item
                href="#"
                v-for="id in intent.conversations_ids"
                :key="'conversation-' + id"
                @click.native.capture.stop="showConversation(id)"
                :active="visitedFallbackConversations.includes(id)"
                ><u># {{ id }} <i class="fa fa-external-link-alt" /></u
              ></b-dropdown-item>
            </b-dropdown>
            <div v-else> N/A </div>
          </b-col>
          <b-col cols="3" v-if="checkPermissions('/intents/<id:int>/mark-classified', 'POST')">
            <b-select v-model="intent.class" :options="intentNames" size="sm" class="w-50 mr-2"></b-select>
            <b-button variant="primary" size="sm" @click="submitIntentClass(intent.id, j)">Set Class</b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="float-right" style="position: fixed; right: 40px; bottom: 1em">
      <b-btn-group v-if="!reqInFlight">
        <b-btn id="save-button-primary" variant="primary" class="box-shadow-2" @click="train()">Train NLU</b-btn>
        <b-tooltip target="save-button-primary" triggers="hover">
          <small>Ctrl + S</small>
        </b-tooltip>
      </b-btn-group>
      <div v-if="reqInFlight">
        <b-spinner type="grow" label="Spinning" variant="secondary"></b-spinner>
        <b-spinner type="grow" label="Spinning" class="mx-3" variant="primary"></b-spinner>
        <b-spinner type="grow" label="Spinning" class="mr-3" variant="secondary"></b-spinner>
      </div>

      <GlobalEvents @keydown.ctrl.shift.83="train()" @keydown.ctrl.83.prevent.exact="train()" />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Fallbacks',
  computed: {
    ...mapFields('rest', ['intents', 'unclassifiedIntents', 'settings', 'evaluation']),
    intentNames() {
      return ["__don't want to specify__"].concat(this.intents.map((x) => x.name))
    },
  },
  data() {
    return {
      editingIntent: null,
      suggestion: null,
      visitedFallbackConversations: [],
      reqInFlight: false,
      edited: false,
    }
  },
  created() {
    this.reloadUncategorizedIntents()
  },
  methods: {
    reloadUncategorizedIntents() {
      this.$store.dispatch('rest/getUnclassifiedIntents', {})
    },
    submitIntentClass(id, index) {
      var selected_class = this.unclassifiedIntents[index].class
      if (selected_class == "__don't want to specify__") {
        this.$store.dispatch('rest/updateIntentSetClassified', {
          params: { id: id },
        })
        this.$delete(this.unclassifiedIntents, index)
        return
      }

      for (const [j, intent] of this.intents.entries()) {
        if (intent.name == selected_class) {
          let template = this.unclassifiedIntents[index].intent
          this.intents[j].templates.push(template)
          this.$store.dispatch('rest/updateIntentSetClassified', {
            params: { id: id },
          })

          this.$store.dispatch('rest/insertIntentTemplate', {
            params: { id: this.intents[j].id },
            data: { template: template },
          })
          this.$delete(this.unclassifiedIntents, index)
          this.edited = true
          break
        }
      }
    },
    editUnclassifiedIntent(intent) {
      this.editingIntent = intent
      this.computeSuggestion()

      this.$nextTick(() => {
        this.$refs['edit-intent-' + intent.id][0].focus()
      })
    },
    computeSuggestion() {
      if (!this.editingIntent) {
        return null
      }
      this.suggestion = null

      this.$store
        .dispatch('rest/postStripStopwords', {
          data: { template: this.editingIntent.intent },
          params: { language: this.settings.language },
        })
        .then((response) => {
          this.suggestion = response.data.preprocessed
        })
    },
    clickSuggestion(index) {
      if (!window.getSelection().toString()) {
        // skip if user selected some text on the suggested intent
        this.unclassifiedIntents[index].intent = this.suggestion
        this.editingIntent = null
      }
    },
    showConversation(id) {
      this.visitedFallbackConversations.push(id)
      let routeData = this.$router.resolve({ name: 'Conversations', query: { conversation_id: id } })
      window.open(routeData.href, '_blank')
    },
    train() {
      this.reqInFlight = true
      this.edited = false
      this.$store
        .dispatch('rest/runTrain')
        .then((x) => {
          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Successfully trained',
          })
        })
        .finally(() => {
          this.$store.dispatch('rest/getEvaluation')
          this.reqInFlight = false
        })
    },
  },
}
</script>
