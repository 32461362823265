<template>
  <div class="float-right" style="position: fixed; right: 40px; bottom: 1em">
    <b-btn-group v-if="!reqInFlight">
      <b-btn id="save-button-secondary" variant="secondary" class="box-shadow-2" @click="updateClick()">Save</b-btn>
      <b-tooltip target="save-button-secondary" triggers="hover">
        <small>Ctrl + S</small>
      </b-tooltip>
      <b-btn id="save-button-primary" variant="primary" class="box-shadow-2" @click="updateClick(true)">{{
        secondButtonText
      }}</b-btn>
      <b-tooltip target="save-button-primary" triggers="hover">
        <small>Ctrl + Shift + S</small>
      </b-tooltip>
    </b-btn-group>
    <div v-if="reqInFlight">
      <b-spinner type="grow" label="Spinning" variant="secondary"></b-spinner>
      <b-spinner type="grow" label="Spinning" class="mx-3" variant="primary"></b-spinner>
      <b-spinner type="grow" label="Spinning" class="mr-3" variant="secondary"></b-spinner>
    </div>

    <GlobalEvents @keydown.ctrl.shift.83="updateClick(true)" @keydown.ctrl.83.prevent.exact="updateClick()" />
  </div>
</template>

<script>
export default {
  name: 'PageSave',
  props: ['firstAction', 'afterFirstActionCB', 'secondButtonText', 'secondAction'],
  computed: {
    loading() {
      for (var p in this.$store.state.rest.pending['intents']) {
        if (this.$store.state.rest.pending[p]) {
          return true
        }
      }
      return false
    },
  },
  data() {
    return {
      reqInFlight: false,
    }
  },
  methods: {
    updateClick(second = false) {
      if (this.reqInFlight) {
        return
      }
      this.reqInFlight = true

      this.firstAction()
        .then(() => {
          var callbacks = []
          if (this.afterFirstActionCB) {
            callbacks.push(this.afterFirstActionCB())
          }
          if (second) {
            callbacks.push(this.secondAction())
          }

          Promise.all(callbacks).finally(() => {
            this.reqInFlight = false
          })

          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Updated',
          })
        })
        .catch(() => {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot save state.',
          })
          this.reqInFlight = false
        })
        .finally(() => {})
    },
  },
  created() {},
}
</script>
