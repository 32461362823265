<template>
  <div>
    <b-modal
      @cancel="onCancel"
      @hide="onModalExit"
      id="passwordModal"
      ref="passwordModal"
      size="xl"
      v-bind:title="'Change password - ' + user.name"
      hide-footer
    >
      {{ cancellable }}
      <b-alert variant="warning" :show="!cancellable">Password expired and must be changed.</b-alert>
      Please provide new password:<br />
      <b-input type="email" autocomplete="username" name="email" v-model="user.email" disabled class="invisible"> </b-input>
      <div class="row">
        <div class="col-6">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend class="w-50">
                <b-input-group-text class="w-100">New Password</b-input-group-text>
              </b-input-group-prepend>
              <b-input
                :state="modalErrors.filter((e) => e.type === 'pass').length === 0 ? undefined : false"
                class="rounded-right"
                type="password"
                v-model="user.passwd"
                autocomplete="new-password"
                @keyup="passwordLiveValidation"
              ></b-input>
              <b-form-invalid-feedback :key="error.message" v-for="error in modalErrors.filter((e) => e.type === 'pass')">
                <div v-html="error.message"> </div>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group class="mt-2">
              <b-input-group-prepend class="w-50">
                <b-input-group-text class="w-100">New Password again</b-input-group-text>
              </b-input-group-prepend>
              <b-input
                :state="modalErrors.filter((e) => e.type === 'pass2').length === 0 ? undefined : false"
                class="rounded-right"
                type="password"
                v-model="user.passwd2"
                autocomplete="new-password"
              ></b-input>
              <b-form-invalid-feedback :key="error.message" v-for="error in modalErrors.filter((e) => e.type === 'pass2')">
                {{ error.message }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <b-button @click="changePasswd">Change my password</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'ChangePasswordModal',
  components: {},
  computed: {
    ...mapFields('rest', ['users']),
  },
  props: ['cancellable', 'user'],
  data() {
    return {
      originalEmail: '',
      modalErrors: [],
      canClose: false,
    }
  },
  methods: {
    passwordLiveValidation() {
      let data = { passwd: this.user.passwd, email: this.user.email, name: this.user.name }

      this.$store.dispatch('rest/validatePassword', { data: data }).then((res) => {
        this.modalErrors = []
        if (res.data.status != 'ok') {
          this.modalErrors.push({
            type: 'pass',
            message: res.data.msg.replace(/\n/g, '<br />'),
          })
        }
      })
    },
    changePasswd() {
      if (!this.user.passwd) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'No password provided.',
        })
      } else if (this.user.passwd == this.user.passwd2) {
        this.modalErrors = []
        delete this.user.passwd2

        let data = { passwd: this.user.passwd, email: this.user.email, name: this.user.name }

        this.$store.dispatch('rest/validatePassword', { data: data }).then((res) => {
          if (res.data.status != 'ok') {
            this.modalErrors.push({
              type: 'pass',
              message: res.data.msg.replace(/\n/g, '<br />'),
            })

            return
          }

          this.$store.dispatch('rest/userChangePasswd', { params: { id: this.user.id }, data: data }).then(() => {
            delete this.user.passwd
            delete this.user.passwd2

            this.$notify({
              group: 'app',
              type: 'success',
              title: 'Password changed',
            })

            this.canClose = true
            this.$refs['passwordModal'].hide()
          })
        })
      } else {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Passwords do not match',
        })
      }
    },
    show() {
      this.$refs['passwordModal'].show()
    },
    onCancel() {
      console.log('cancel')
    },
    onModalExit(bvModalEvent) {
      if (!this.cancellable && !this.canClose) {
        bvModalEvent.preventDefault()
        this.$notify({
          group: 'app',
          type: 'warn',
          title: 'Password expired and must be changed.',
        })
      }
    },
    saveNewPassword() {},
  },
}
</script>
