<template>
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-secondary sidebar sidebar-dark accordion" id="accordionSidebar">
    <div class="text-center mt-2 small">
      <i class="fa fa-phone text-primary" />
      <i class="fa fa-comment text-white mx-3" />
      <i class="fa fa-microphone text-primary" />
      <!-- <i class="fa fa-circle text-primary" />
            <i class="fa fa-circle text-white mx-3" />
            <i class="fa fa-circle text-primary" /> -->
    </div>
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center pt-1" href="#">
      <!-- <div class="sidebar-brand-icon"> -->
      <div class="sidebar-brand-text mx-3">Chytre <br />Callcentrum</div>
    </a>
    <!-- <small class=" mx-auto mt-0 ">By Happy Robots</small> -->

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <!-- <li class="nav-item active">
        <router-link to="/app/index" class="nav-link">
          <i class="fa fa-fw fa-microphone"></i>
          <span>
              {{ settings.name }}
          </span><i class="fa fa-angle-down" aria-hidden="true"> </i>
        </router-link>
      </li> -->
    <b-dropdown
      right
      id="projectsDropdown"
      :text="settings.name"
      :class="search ? 'my-md-2' : 'm-md-2'"
      variant="link secondary"
      ref="scriptsDropdown"
      toggle-class="text-decoration-none nohover text-light overflow-auto"
      @toggle="cancelSearch"
    >
      <b-dropdown-header v-show="!search" @click="toggleSearchProjects">
        <div class="d-flex flex-wrap justify-content-between cursor-pointer">
          <span>Select script</span>
          <b-tooltip target="search-button" triggers="hover">
            <small>Ctrl + /</small>
          </b-tooltip>
          <i class="fa fa-search ml-1" id="search-button"></i>
        </div>
      </b-dropdown-header>
      <autocomplete
        v-show="search"
        :autoSelect="true"
        placeholder="Type to search..."
        ref="projectsSearch"
        class="projects_search"
        :get-result-value="
          (p) => {
            return p.name
          }
        "
        :search="searchInProjects"
        @submit="
          (p) => {
            if (p) {
              selectScript(p.id)
            }
          }
        "
        @keydown.down.stop
        @keydown.up.stop
      >
        <template #result="{ result, props }">
          <a role="menuitem" target="_self" href="#" class="dropdown-item" v-bind="props">
            {{ result.name }}
          </a>
        </template>
      </autocomplete>

      <GlobalEvents @keydown.ctrl.191.prevent="toggleSearchProjects" />
      <GlobalEvents @keydown.ctrl.ú.prevent="toggleSearchProjects" />
      <GlobalEvents @keydown.ctrl.[.prevent="toggleSearchProjects" />

      <template v-slot:button-content>
        <span v-if="axios.defaults.params && getProjectsById[axios.defaults.params.project_id]">
          {{ getProjectsById[axios.defaults.params.project_id].name }}
        </span>
      </template>
      <div style="height: 400px; overflow-y: auto" v-if="!search">
        <div v-for="(p, i) in sortedProjects" :key="i">
          <b-dropdown-item :active="settings.id == p.id" :ref="'projectItem' + i" @click="selectScript(p.id)"
            >{{ p.name }}
          </b-dropdown-item>
        </div>
      </div>
      <b-dropdown-divider v-if="checkPermissions('/system/projects', 'POST')"></b-dropdown-divider>
      <b-dropdown-item @click="newScript" v-if="checkPermissions('/system/projects', 'POST')">New Project </b-dropdown-item>
    </b-dropdown>

    <!-- Divider -->
    <hr class="sidebar-divider" />

    <GlobalEvents @keydown.ctrl.g.prevent="navigateMenu = true" :v-if="!navigateMenu" />
    <div v-if="navigateMenu">
      <!-- GlobalEvents's modifier cannot be dynamic -->
      <GlobalEvents @keydown.d="shortcutNavigate('dashboard')" />
      <GlobalEvents @keydown.g="shortcutNavigate('grapheditor')" />
      <GlobalEvents @keydown.a="shortcutNavigate('actions')" />
      <GlobalEvents @keydown.i="shortcutNavigate('intents')" />
      <GlobalEvents @keydown.e="shortcutNavigate('entities')" />
      <GlobalEvents @keydown.s="shortcutNavigate('settings')" />
      <GlobalEvents @keydown.r="shortcutNavigate('storage')" />
      <GlobalEvents @keydown.f="shortcutNavigate('fallbacks')" />
      <GlobalEvents @keydown.t="shortcutNavigate('tests')" />
      <GlobalEvents @keydown.c="shortcutNavigate('logs')" />
      <GlobalEvents @keydown.o="shortcutNavigate('outbound')" />
      <GlobalEvents @keydown.p="shortcutNavigate('deployments')" />
      <GlobalEvents @keydown.h="shortcutNavigate('history')" />
      <GlobalEvents @keydown.k="shortcutNavigate('knowledge')" />
    </div>

    <NavLink
      :header="'Dashboard' | highlightMenuShortcut(navigateMenu, 'D')"
      link="/app/dashboard"
      iconName="fa-chart-area"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/stats', 'GET')"
    />
    <NavLink
      :header="'Graph Editor' | highlightMenuShortcut(navigateMenu, 'G')"
      link="/app/grapheditor"
      iconName="fa-project-diagram"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/reactions', 'GET')"
    />
    <NavLink
      :header="'Actions' | highlightMenuShortcut(navigateMenu, 'A')"
      link="/app/actions"
      iconName="fa-code"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/actions', 'GET')"
    />
    <NavLink
      :header="'Intents' | highlightMenuShortcut(navigateMenu, 'I')"
      link="/app/intents"
      iconName="fa-quote-left"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/intents', 'GET')"
    />
    <NavLink
      :header="'Entities' | highlightMenuShortcut(navigateMenu, 'E')"
      link="/app/entities"
      iconName="fa-at"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/entities', 'GET')"
    />
    <NavLink
      :header="'Settings' | highlightMenuShortcut(navigateMenu, 'S')"
      link="/app/settings"
      iconName="fa-cog"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/settings', 'GET')"
    />
    <NavLink
      :header="'Storage' | highlightMenuShortcut(navigateMenu, 'r')"
      link="/app/storage"
      iconName="fa-folder"
      index="storage"
      isHeader
      v-if="checkPermissions('/storage', 'GET')"
    />
    <NavLink
      :header="'Fallbacks' | highlightMenuShortcut(navigateMenu, 'F')"
      link="/app/fallbacks"
      iconName="fa-bell"
      index="storage"
      isHeader
      v-if="checkPermissions('/intents/unclassified', 'GET')"
    />
    <NavLink
      :header="'Tests' | highlightMenuShortcut(navigateMenu, 'T')"
      link="/app/tests"
      iconName="fas fa-vial"
      index="pamscript"
      isHeader
      v-if="checkPermissions('/tests', 'GET')"
    />
    <NavLink
      :header="'Conversations' | highlightMenuShortcut(navigateMenu, 'C')"
      link="/app/logs"
      iconName="far fa-comments"
      index="logs"
      isHeader
      v-if="checkPermissions('/conversations', 'GET')"
    />
    <NavLink
      :header="'Outbound' | highlightMenuShortcut(navigateMenu, 'O')"
      link="/app/outbound"
      iconName="fas fas fa-bullhorn"
      index="logs"
      isHeader
      v-if="checkPermissions('/outbound/campaigns', 'GET') && !settings.is_chat"
    />
    <NavLink
      :header="'Deployments' | highlightMenuShortcut(navigateMenu, 'p')"
      link="/app/deployments"
      iconName="fa-cloud"
      index="deployments"
      isHeader
      v-if="checkPermissions('/deployments', 'GET')"
    />
    <NavLink
      :header="'History' | highlightMenuShortcut(navigateMenu, 'H')"
      link="/app/history"
      iconName="fa-history"
      index="history"
      isHeader
      v-if="checkPermissions('/history', 'GET')"
    />
    <NavLink
      :header="'Knowledge Base' | highlightMenuShortcut(navigateMenu, 'K')"
      link="/app/knowledge"
      iconName="fa-book"
      index="knowledge"
      isHeader
      v-if="checkPermissions('/knowledges', 'GET')"
    />
    <!-- <NavLink
      :header="'QA Trainings <sup>beta</sup>' | highlightMenuShortcut(navigateMenu, 'Q')"
      link="/app/qa-trainings"
      iconName="fa-question"
      index="knowledge"
      isHeader
      v-if="checkPermissions('/knowledge', 'GET')"
    /> -->

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <li class="nav-item">
      <a href="/docs" class="nav-link text-muted" target="_blank">
        <span class="nav-item">
          <i class="fa fa-fw fa-book-open"></i>
        </span>
        Documentation
        <i class="fas fa-external-link-alt"></i>
      </a>
    </li>

    <!-- Sidebar Toggler (Sidebar) -->
    <!-- <div class="text-center d-none d-md-inline">
        <button class="rounded-circle border-0" id="sidebarToggle"></button>
      </div> -->
  </ul>
  <!-- End of Sidebar -->
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import isScreen from '@/core/screenHelper'
import NavLink from './NavLink/NavLink'

export default {
  name: 'Sidebar',
  components: { NavLink },
  data() {
    return {
      search: false,
      searchExpr: '',
      navigateMenu: false,
      navigateMenuTimeout: null,
      // navigationShortcuts: {
      //   d: 'dashboard',
      //   g: 'grapheditor',
      //   a: 'actions',
      //   i: 'intents',
      //   e: 'entities',
      //   s: 'settings',
      //   r: 'storage',
      //   f: 'fallbacks',
      //   t: 'tests',
      //   c: 'logs',
      //   o: 'outbound',
      //   p: 'deployments',
      // },
    }
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/')
      paths.pop()
      this.changeSidebarActive(paths.join('/'))
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false)
        this.setActiveByRoute()
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true)
        this.changeSidebarActive(null)
      }
    },
    selectScript(projectId) {
      if (!this.$auth.user().permissions.project_roles[projectId]) {
        return this.$notify({
          group: 'app',
          type: 'error',
          title: 'Insufficient permissions',
          text: 'Cannot change project. User not assigned to this project',
          duration: 5000,
        })
      }

      this.cancelSearch()
      this.$events.$emit('Projects:changeProject', projectId)
      this.chatOpened = false
    },
    newScript() {
      this.$store.dispatch('rest/createProject', {}).then((state) => {
        if (!state.data.project_id) {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot create new project.',
          })
        } else {
          this.$notify({
            group: 'app',
            type: 'success',
            title: 'New script ready',
          })

          setTimeout(() => {
            this.$store.dispatch('rest/getProjects', {}).then(() => {
              this.$auth.fetch().then(() => {
                this.selectScript(state.data.project_id)
              })
            })
          }, 400) // to show previous notification
        }
      })
    },
    searchInProjects(expr) {
      let by = (a, b) => {
        return a.name.localeCompare(b.name)
      }
      if (!this.search || expr.trim().length === 0) {
        return this.projects.sort(by)
      }
      expr = expr.trim().toLowerCase()
      return this.projects.filter((p) => p.name.toLowerCase().includes(expr)).sort(by)
    },
    toggleSearchProjects() {
      if (this.search) {
        this.search = false
        this.$refs.scriptsDropdown.hide()
        return
      }
      this.search = true
      this.$refs.scriptsDropdown.show()
      setTimeout(() => {
        document.querySelector('.projects_search .autocomplete-input').focus()
      }, 20)
    },
    cancelSearch() {
      this.search = false
    },
    shortcutNavigate(location) {
      if (this.$router.currentRoute.path != '/app/' + location) {
        this.$router.push('/app/' + location)
      }
      this.navigateMenu = false
    },
  },
  created() {
    this.setActiveByRoute()
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: (state) => state.sidebarStatic,
      sidebarOpened: (state) => !state.sidebarClose,
      activeItem: (state) => state.sidebarActiveElement,
    }),
    ...mapFields('rest', ['settings', 'projects', 'chatOpened']),
    ...mapGetters('rest', ['getProjectsById']),
    sortedProjects() {
      let by = (a, b) => {
        return a.name.localeCompare(b.name)
      }
      return this.projects.sort(by)
    },
  },
  watch: {
    navigateMenu(n, o) {
      clearTimeout(this.navigateMenuTimeout)
      this.navigateMenuTimeout = setTimeout(() => {
        this.navigateMenu = false
      }, 3000)
    },
  },
}
</script>

<style src="./Sidebar.scss" lang="scss" />
