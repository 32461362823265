<template>
  <!-- stop rendering if axios setup not done (v-if) and refresh when there is change (:key) -->
  <div v-if="axiosSetupDone" :key="axiosSetupDone">
    <nprogress-container></nprogress-container>
    <loading
      :active="loading"
      :is-full-page="false"
      background-color="#343a40"
      color="#f24516"
      :z-index="100000"
      :opacity="0.2"
      loader="dots"
      :width="80"
      :can-cancel="true"
    ></loading>

    <router-view />
    <notifications group="app" position="bottom left" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'

function key_from_endpoint_and_response(config, response) {
  let endpoint = config.url.replace(config.baseURL, '')
  if (response != null && response.id !== undefined && endpoint.includes('/-1')) {
    endpoint = endpoint.replace('/-1', '/' + response.id)
  }
  return endpoint
}

function relevant_atime(atimes, config) {
  const request_key = key_from_endpoint_and_response(config, undefined)
  return atimes[
    Object.keys(atimes)
      .filter((key) => request_key.includes(key))
      .reduce(
        (longest_key, key) => (longest_key == undefined || longest_key.length < key.length ? key : longest_key),
        undefined
      )
  ]
}

export default {
  name: 'App',
  components: { Loading, NprogressContainer },
  computed: {
    ...mapGetters('rest', ['getActionsPathsMap']),
  },
  data() {
    return {
      loading: false,
      axiosSetupDone: 0,
      lastEventTsForLogout: Date.now(),
    }
  },
  created() {
    this.$events.$on('Projects:changeProject', (projectId) => {
      this.axiosSetupDone += 1
      this.axios.defaults.params.project_id = projectId
      localStorage.project_id = projectId

      this.$router.push({ query: { conversation_id: undefined } }).catch(() => {}) // catch NavigationDuplicated

      // update projects permissions
      this.$root.initData()
    })
    this.$events.$on('axiosSetupDone', () => {
      this.axiosSetupDone += 1

      const originalDispatch = this.$store.dispatch
      const actionsPaths = this.getActionsPathsMap
      const checkPermissions = this.checkPermissions

      this.$store.dispatch = function (type, payload) {
        if (actionsPaths[type]) {
          if (!checkPermissions(actionsPaths[type].path, actionsPaths[type].method)) {
            // console.log('Insufficient permissions for ', type, actionsPaths[type].path, actionsPaths[type].method)
            return Promise.resolve(undefined)
          }
        }

        return originalDispatch.apply(this, arguments)
      }
    })

    // automatic logout with idle timeout
    setInterval(() => {
      if (Date.now() - this.lastEventTsForLogout > process.env.VUE_APP_LOGIN_SESSION_EXPIRATION_HOURS * 1000 * 60 * 60) {
        if (this.$router.history.current.path != '/login') {
          this.$auth
            .logout({
              makeRequest: true,
              params: {}, // data: {} in axios
              success: function () {},
              error: function () {},
              redirect: '/login',
            })
            .then(() => {
              this.$store.state.pam.beforeLoginSite = this.$router.history.current.path

              this.$notify({
                group: 'app',
                type: 'info',
                title: 'Info',
                text: 'Logged out due to inactivity',
                duration: -1,
              })
            })
            .catch(() => {
              // no internet connection
            })
        }
      }
    }, 60 * 1000)

    const events = ['scroll', 'keydown', 'mousedown', 'touchstart']

    events.forEach((event) => {
      window.addEventListener(event, () => {
        this.lastEventTsForLogout = Date.now()
      })
    })

    this.axios.interceptors.request.use((config) => {
      if (config.url.includes('/undefined') || config.url.includes('/null')) {
        config.url = config.url.replace('/undefined', '/-1')
        config.url = config.url.replace('/null', '/-1')
      }
      if (['get', 'options'].includes(config.method)) {
        return config
      }
      const atime = relevant_atime(this.$store.state.metadata.atimes, config)
      if (atime !== undefined && !config.url.includes('/file')) {
        Object.assign(config.params, {
          atime,
        })
      }
      return config
    }, undefined)

    this.axios.interceptors.response.use(
      (success) => {
        if (success.headers['x-atime'] !== undefined && ['get', 'post', 'put', 'delete'].includes(success.config.method)) {
          this.$store.dispatch('metadata/recordAtime', {
            [key_from_endpoint_and_response(success.config, success.data)]: success.headers['x-atime'],
          })
        }
        return success
      },
      (error) => {
        if (!error.response) {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Backend Connection Error!',
            text: 'Please check your internet connection.',
          })
          return Promise.reject('Backend Connection Error')
        }
        var status = error.response.status
        if (status) {
          if (status === 401 || status === 403) {
            console.log('rest req err -> login', error)

            if (error.config.params['ignore403']) {
              return
            }

            if (this.$router.history.current.path != '/login') {
              this.$store.state.pam.beforeLoginSite = this.$router.history.current.path
              this.$auth.logout({
                makeRequest: false,
                params: {}, // data: {} in axios
                success: function () {},
                error: function () {},
                redirect: '/login',
              })
            }
          } else if (status === 400 || status == 500) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: 'Error!',
            })
          } else if (status === 402) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: 'Insufficient permissions.',
              text: 'If the administrator recently changed permissions, try reload the page.',
            })
          } else if (status === 409) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: 'Conflict! Data not saved.',
              text: 'A concurrent edit occurred.',
            })
          }
        }
        return Promise.reject(error)
      }
    )

    this.loading = true
    this.$auth.ready(function () {
      if (this.$auth.check()) {
        this.$root.initAll().then(() => {
          this.loading = false
        })
      } else {
        this.$store.state.pam.beforeLoginSite = this.$route.path
        this.$router.push('/login').catch((x) => {})
        this.loading = false
        this.$events.$emit('axiosSetupDone', true) // login page cannot make axios ready
      }
    })
  },
  methods: {},
  watch: {
    $route() {
      let isUserLoggedIn = this.$auth.check()
      if (!isUserLoggedIn && this.$route.path !== '/login') {
        console.log('unauthorized -> login')
        this.$store.state.pam.beforeLoginSite = this.$route.path
        this.$router.push('/login')
      } else if (isUserLoggedIn && this.$route.path === '/login') {
        this.$router.push(this.$store.state.pam.beforeLoginSite || '/app/dashboard')
      }
    },
  },
}
</script>

<style src="./styles/sb-admin-2.scss" lang="scss" />
