// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import _ from 'lodash'
Vue.prototype._ = _

import './styles/app.scss'

import store from './store'
import router from './Routes'
import App from './App'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(TextareaAutosize)

Vue.prototype.$events = new Vue()

Vue.router = router // vue-auth needs that

import Notifications from 'vue-notification'
Vue.use(Notifications)

import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

import NProgress from 'vue-nprogress'
const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  http: false, // Show progressbar when doing Vue.http, default: true
}
Vue.use(NProgress, options)

const nprogress = new NProgress()
nprogress.configure({ showSpinner: false, latencyThreshold: 1000 })

// auth.js
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

const serverUrl = new URL(process.env.VUE_APP_API_ROOT || document.currentScript.src)
if (serverUrl.port == 8080) {
  //dev frontend server, use dev backend server
  serverUrl.port = 8000
}
Vue.axios.defaults.baseURL = serverUrl.protocol + '//' + serverUrl.host + '/'

Vue.use(require('@websanova/vue-auth'), {
  refreshData: { enabled: false, interval: 0 },
  auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
})

import VueForceNextTick from 'vue-force-next-tick'
Vue.use(VueForceNextTick)

import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
Vue.use(Autocomplete)

var axiosSetupDone = false

Vue.mixin({
  methods: {
    deepClone: (obj) => JSON.parse(JSON.stringify(obj)),
    checkPermissions(path, method, env = null) {
      if (!axiosSetupDone || !this.$auth.user().permissions) {
        return false // will be rerendered after axios ready
      }

      method = method.toUpperCase()
      if (typeof path == 'function') {
        // path as a function is used in the middleware (axios interceptor)
        // eval path defined as:  path: ({ id }) => `/v1/roles/${id}`,
        path = path({ id: '<id:int>' })
      }

      path = path.replace('/v1', '').replace('/v2', '').replace('/v3', '')

      let projectRoles = this.$auth.user().permissions.project_roles
      let systemPermissions = this.$auth.user().permissions.system
      let roles = this.$auth.user().permissions.roles
      let restrictedPaths = this.$auth.user().permissions.restricted_paths

      if (!restrictedPaths[method + ':' + path] && !restrictedPaths[method + ':/system' + path]) {
        return true
      }

      if (systemPermissions[path]) {
        if (systemPermissions[path][method]) {
          return true
        }
      }
      if (systemPermissions['/system' + path]) {
        if (systemPermissions['/system' + path][method]) {
          return true
        }
      }

      let roleId = projectRoles[this.axios.defaults.params.project_id]

      try {
        if (roles[roleId][path][method].includes(env) || env == null) {
          return true
        }
      } catch (e) {
        // role, path or method does not exists
      }

      return false
    },
    /*
      Computes changes in array of objects. These objects must have id params. Returns:

      return {
        deleted: [deletedItems],
        created: [createdItems],
        changed: [changedItems],
      }
    */
    diffArrOfObjectsById(original, current) {
      function byId(arr) {
        let o = {}
        for (let i of arr) {
          o[i.id] = i
        }
        return o
      }

      let oById = byId(original)
      let cById = byId(current)

      let deleted = []
      let created = []
      let changed = []

      current.map((x) => {
        if (!oById[x.id]) {
          created.push(x)
        }
      })
      original.map((x) => {
        if (!cById[x.id]) {
          deleted.push(x)
        }
      })

      for (const [id, c] of Object.entries(cById)) {
        if (oById[id] && JSON.stringify(oById[id]) != JSON.stringify(c)) {
          changed.push(c)
        }
      }

      return {
        deleted: deleted,
        created: created,
        changed: changed,
      }
    },
  },
})

//////////////
//// FIX https://github.com/dbrekalo/vue-date-pick/issues/28
//////////////

/*
 * Recursively merge properties of two objects
 */
function mergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = mergeRecursive(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p]
    }
  }

  return obj1
}

import DatePick from 'vue-date-pick'

Vue.component(
  'date-pick',
  mergeRecursive(DatePick, {
    methods: {
      addCloseEvents() {
        if (!this.closeEventListener) {
          this.closeEventListener = (e) => this.inspectCloseEvent(e)
          ;['click', 'keyup'].forEach((eventName) => document.addEventListener(eventName, this.closeEventListener))
        }
      },
    },
  })
)

//////////////
//// END FIX
//////////////

import './filters'

/* eslint-disable no-new */
new Vue({
  el: '#app',
  nprogress,
  store,
  router,
  render: (h) => h(App),
  methods: {
    initData() {
      this.$store.dispatch('rest/getStats', {
        params: { project_env: this.checkPermissions('/stats', 'GET', 'prod') ? 'prod' : 'dev' },
      })
      this.$store.dispatch('rest/getReactions', {})
      this.$store.dispatch('rest/getSettings', {})
      this.$store.dispatch('rest/getIntents', {})
      this.$store.dispatch('rest/getEvaluation', {})
      this.$store.dispatch('rest/getUnclassifiedIntents', {})
      this.$store.dispatch('rest/getReactionsScopes', {})
      this.$store.dispatch('rest/getActions', {})
      this.$store.dispatch('rest/getEntities', {})
      this.$store.dispatch('rest/getTests', {})
      this.$store.dispatch('rest/getDeployments', {})
      this.$store.dispatch('rest/getSynonyms', {})
      this.$store.dispatch('rest/getProjectToken', {})
      this.$store.dispatch('rest/getOutboundCampaigns', {})
      this.$store.dispatch('rest/getLabels', {})

      this.$store.state.rest.reactionsFlow = {}
      this.$store.state.rest.storage = []
    },
    initAll() {
      this.$auth.apiReady = () => {
        return new Promise((resolve) => {
          if (axiosSetupDone) {
            resolve()
          }

          let timeout = setInterval(() => {
            if (axiosSetupDone) {
              clearInterval(timeout)
              resolve()
            }
          }, 50)
        })
      }

      return new Promise((resolve) => {
        let userLoaded = new Promise((resolve) => {
          if (!this.$auth.user().projects) {
            this.$auth.fetch().then(() => {
              resolve()
            })
          } else {
            resolve()
          }
        })

        userLoaded.then(() => {
          let projects = this.$auth.user().projects
          this.setUsersDefaults()
          if (projects && projects.length > 0) {
            this.$store.dispatch('rest/getProjects', {})
            this.initData()
            this.$store.dispatch('rest/getLanguages', {})
            this.$store.dispatch('rest/getVoices', {})
            this.$store.dispatch('rest/getUsers', {})
            this.$store.dispatch('rest/getCompanies', {})
            resolve()
          } else {
            if (!this.checkPermissions('/projects', 'POST')) {
              alert(
                'ERROR: No projects associated with this user and this user cannot create a new project ' +
                  '(insufficient permissions). Please contact your platform administrator'
              )
              return
            }
            this.$store.dispatch('rest/createProject', {}).then((state) => {
              if (state.data.project_id) {
                this.axios.defaults.params.project_id = state.data.project_id
                localStorage.project_id = state.data.project_id

                this.$notify({
                  group: 'app',
                  type: 'success',
                  title: 'Welcome! We created a brand new project for you.',
                })

                this.$store.dispatch('rest/runTrain').then(() => {
                  this.$notify({
                    group: 'app',
                    type: 'success',
                    title: 'NLU model ready',
                  })
                })

                this.$auth.refresh({
                  success: () => {
                    this.initData()
                    resolve()
                  },
                })
              }
            })
          }
        })
      })
    },
    setUsersDefaults() {
      let url = new URL(window.location.href.replaceAll('#/', ''))
      let project_id = Number(url.searchParams.get('project_id'))

      if (!project_id) {
        project_id = Number(localStorage.project_id)
      }
      if (isNaN(project_id) && this.$auth.user().projects && this.$auth.user().projects.length) {
        project_id = this.$auth.user().projects[0]
      }
      if (!project_id || !this.$auth.user().projects) {
        project_id = -1
      }

      if (project_id != -1 && !this.$auth.user().projects.includes(project_id)) {
        project_id = this.$auth.user().projects[0]
      }

      this.axios.defaults.params = {
        user_id: this.$auth.user().id,
        project_id: project_id,
      }

      axiosSetupDone = true
      this.$events.$emit('axiosSetupDone', true)
    },
  },
})
