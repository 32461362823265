<template>
  <span>
    {{ label }}
    <span @click="editing = true" v-show="!editing">{{ value }}</span>
    <span v-show="editing">
      <input
        :value="value"
        ref="editable"
        @keydown.esc="abort()"
        @keydown.enter="finish()"
        type="text"
        :class="'form-control ' + active_class"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'Editable',
  props: ['label', 'value', 'active_class'],
  data() {
    return {
      editing: false,
      originalValue: undefined,
    }
  },
  methods: {
    edit() {
      this.editing = true
    },
    toogle() {
      if (this.editing) {
        this.finish()
      } else {
        this.edit()
      }
    },
    finish() {
      this.editing = false
      this.$emit('input', this.$refs.editable.value)
    },
    abort() {
      this.$refs['editable'].value = this.originalValue
      this.editing = false
    },
  },
  mounted: function () {
    this.originalValue = this.$refs['editable'].value
  },
}
</script>

<style src="./Editable.scss" lang="scss" />
