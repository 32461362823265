<template>
  <div class="error-page">
    <b-container>
      <div class="errorContainer mx-auto m-3">
        <h2 class="text-secondary">404</h2>
        <p class="errorInfo">Opps, it seems that this page does not exist.</p>
        <p>
          <router-link to="/" class="nav-link">
            <b-button variant="primary" size="xl">Return to the homepage</b-button>
          </router-link>
        </p>
        <p class="errorHelp pb-3">If you are sure it should, please report a bug.</p>
      </div>
      <!-- <footer class="pageFooter text-primary">Console ChytreCallcentrum.cz</footer> -->
      <footer class="pageFooter sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Feedback? Send us a message <a href="mailto:info@chytrecallcentrum.cz">info@chytrecallcentrum.cz</a> </span>
          </div>
        </div>
      </footer>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
}
</script>

<style src="./Error.scss" lang="scss" scoped />
