<template>
  <div>
    <h1 class="page-title">Storage</h1>

    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Storage - files</h6>
      </div>
      <!-- Card Body -->
      <div class="card-body">
        <div v-if="checkPermissions('/file', 'POST')">
          <label>Upload project files</label>
          <div class="custom-file b-form-file">
            <b-row>
              <b-col cols="8">
                <b-form-file
                  ref="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  multiple
                  @change="handleFileUpload"
                ></b-form-file>
              </b-col>
              <b-col>
                <div v-show="uploading">
                  <b-spinner type="grow" label="Loading..." variant="primary"></b-spinner>
                </div>
              </b-col>
            </b-row>
          </div>

          <p class="mt-3">Here You can upload Your project files accessible during the script execution.</p>
          <p class="ml-3">
            <a href="/docs/storage/" target="_blank">
              Documentation
              <i class="fas fa-external-link-alt"></i>
            </a>
          </p>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>File name</th>
                <th>Size</th>
                <th>Type</th>
                <th>Version</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(f, i) in storage" :key="i">
                <td>{{ f.name }}</td>
                <td>{{ f.size | formatBytes }}</td>
                <td>{{ f.type }}</td>
                <td>{{ f.version | formatDate }}</td>
                <td>
                  <i class="fa fa-download mx-2 actionIcon" @click.stop="download(f.name)"></i>
                  <i
                    class="fa fa-trash mx-2 actionIcon"
                    @click.stop="remove(f.name)"
                    v-if="checkPermissions('/file', 'DELETE')"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Storage',
  components: {},
  computed: {
    ...mapFields('rest', ['storage']),
  },
  data() {
    return {
      uploading: false,
    }
  },
  methods: {
    download(file) {
      this.axios
        .get('v1/file', {
          responseType: 'blob',
          params: {
            file: file,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', file)
          document.body.appendChild(link)
          link.click()
        })
    },
    remove(file) {
      this.$bvModal
        .msgBoxConfirm('Do You really want to delete file?', {
          title: 'Remove ' + file,
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.axios
              .delete('v1/file', {
                params: { file: file },
              })
              .then(() => {
                this.$notify({
                  group: 'app',
                  type: 'success',
                  title: 'File deleted',
                })
              })
              .catch(() => {
                this.$notify({
                  group: 'app',
                  type: 'error',
                  title: 'Error!',
                  text: 'Cannot delete file',
                })
              })
              .finally(() => {
                this.$store.dispatch('rest/getStorage', {}).finally(() => (this.uploading = false))
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleFileUpload(e) {
      let formData = new FormData()
      let filenames = []
      let files = e.type === 'drop' ? e.dataTransfer.files : e.target.files
      for (var f of files) {
        filenames.push(f.name)
        formData.append('file', f, f.name)
      }

      this.$bvModal
        .msgBoxConfirm('Do You really want to (re)upload files?', {
          title: '(Re)upload ' + filenames.join(', '),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.uploading = true

            this.axios
              .post('/v1/file', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(() => {
                this.$notify({
                  group: 'app',
                  type: 'success',
                  title: 'Successfully uploaded',
                })
              })
              .catch(() => {
                this.$notify({
                  group: 'app',
                  type: 'error',
                  title: 'Error!',
                  text: 'Cannot upload files!',
                })
              })
              .finally(() => {
                e.target.value = ''
                this.$store.dispatch('rest/getStorage', {}).finally(() => {
                  this.uploading = false
                  this.$refs.file.reset()
                })
              })
          } else {
            this.$refs.file.reset()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created: function () {
    if (!this.storage.length) {
      this.$auth.apiReady().then(() => {
        this.$store.dispatch('rest/getStorage', {})
      })
    }
  },
}
</script>

<style src="./Storage.scss" lang="scss" scoped />
