<template>
  <li class="nav-item">
    <router-link :to="link" class="nav-link">
      <span class="nav-item">
        <i :class="fullIconName"></i>
      </span>
      <span v-html="header"></span><sup v-if="label" class="headerLabel">{{ label }}</sup>
    </router-link>
  </li>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavLink',
  props: {
    badge: { type: String, dafault: '' },
    header: { type: String, default: '' },
    iconName: { type: String, default: '' },
    headerLink: { type: String, default: '' },
    link: { type: String, default: '' },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: '' },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    activeItem: { type: String, default: '' },
    label: { type: String },
    index: { type: String },
  },
  data() {
    return {
      headerLinkWasClicked: true,
    }
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive']),
    togglePanelCollapse(link) {
      this.changeSidebarActive(link)
      this.headerLinkWasClicked = !this.headerLinkWasClicked || !this.activeItem.includes(this.index)
    },
  },
  computed: {
    fullIconName() {
      return `fa fa-fw ${this.iconName}`
    },
    isActive() {
      return this.activeItem && this.activeItem.includes(this.index) && this.headerLinkWasClicked
    },
  },
}
</script>

<style src="./NavLink.scss" lang="scss" scoped />
