<template>
  <div>
    <b-list-group>
      <b-list-group-item v-if="checkPermissions('/synonyms', 'PUT')">
        <div v-if="newSynonymErr" class="alert alert-danger p-1" role="alert">{{ newSynonymErr }}</div>
        <label>Add Synonym</label>
        <input
          v-model="newSynonymName"
          placeholder="Synonym Indentifier"
          @keypress.enter="addNewSynonym()"
          class="rounded ml-2"
        />
        <b-button @click="addNewSynonym()" size="sm" variant="primary" class="ml-3 box-shadow-2">Add</b-button>
      </b-list-group-item>

      <b-list-group-item v-for="(synonym, k) in synonyms" :key="k" class="p-2">
        <div class="d-flex">
          <i class="fas fa-hashtag fa-xs m-2"></i>
          <div v-show="editSynonymName != k" class="flex-grow-1" @click="toogleExpand(k)">{{ synonym.name }}</div>
          <span v-show="editSynonymName == k" class="flex-grow-1">
            <input v-model="editSynonymNameNewVal" class="rounded ml-2" />
            <b-button @click="saveNewSynonymName()" size="sm" variant="primary" class="mx-3 box-shadow-2">Rename</b-button>
          </span>

          <div class="float-right">
            <i class="fa fa-caret-down ml-1" @click="toogleExpand(k)" :class="{ 'fa-caret-up': expanded[k] }"></i>
            <i
              class="fa fa-trash-alt ml-2"
              @click="removeSynonym(k, synonym.name)"
              v-if="checkPermissions('/synonyms', 'PUT')"
            ></i>
          </div>
        </div>

        <div v-if="expanded[k]">
          <input
            placeholder="Synonym"
            :ref="'new-synonym-' + k"
            @keypress.enter="addSynonymTemplate(k)"
            class="rounded ml-2"
            v-if="checkPermissions('/synonyms', 'PUT')"
          />
          <b-button
            @click="addSynonymTemplate(k)"
            size="sm"
            variant="primary"
            class="ml-3 box-shadow-2"
            v-if="checkPermissions('/synonyms', 'PUT')"
            >Add Synonym</b-button
          >

          <ul>
            <li v-for="(value, i) in synonym.values" :key="i" :class="{ 'bg-gray-200': i % 2 !== 0 }">
              <span class="cursor-pointer d-flex">
                <Editable
                  v-model="synonyms[k].values[i]"
                  label
                  class="flex-grow-1"
                  :ref="'synonym-template-edit-' + k + '-' + i"
                />
                <i
                  class="float-right fa fa-pencil-alt pr-2"
                  @click="$refs['synonym-template-edit-' + k + '-' + i][0].toogle()"
                  v-if="checkPermissions('/synonyms', 'PUT')"
                ></i>
                <i
                  class="float-right fa fa-trash-alt"
                  @click="removeSynonymTemplate(k, i)"
                  v-if="checkPermissions('/synonyms', 'PUT')"
                ></i>
              </span>
            </li>
          </ul>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Editable from '@/components/Editable/Editable'
import Vue from 'vue'

export default {
  components: { Editable },
  computed: {
    ...mapFields('rest', ['synonyms']),
  },
  data() {
    return {
      expanded: {},
      editSynonymName: undefined,
      editSynonymNameNewVal: undefined,
      newSynonymName: undefined,
      newSynonymErr: undefined,
    }
  },
  created() {},
  methods: {
    inputChange(event) {
      this.$emit('update:model', event)
    },
    toogleExpand(synonym) {
      Vue.set(this.expanded, synonym, !this.expanded[synonym])
    },
    removeSynonym(k, name) {
      this.$bvModal
        .msgBoxConfirm('Do You really want to remove Synonym?', {
          title: 'Remove ' + name,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.delete(this.synonyms, k)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addNewSynonym() {
      if (!this.newSynonymName) {
        this.newSynonymErr = 'Synonym name cannot be empty'
        return
      }
      if (this.newSynonymName.indexOf(' ') != -1) {
        this.newSynonymErr = 'Synonym name cannot contain spaces'
        return
      }

      if (this.checkDuplicitNames(this.newSynonymName)) {
        this.newSynonymErr = 'Duplicit synonym name'
        return
      }

      this.synonyms.unshift({
        name: this.newSynonymName,
        values: [this.newSynonymName], // use name as a default synonym
      })
      ;(this.expanded = {}), this.toogleExpand(0)
      this.newSynonymName = ''
      this.newSynonymErr = undefined
    },
    saveNewSynonymName() {
      if (this.editSynonymNameNewVal === this.synonyms[this.editSynonymName].name) {
        // no renaming
        this.editSynonymName = undefined
        return
      }
      if (this.checkDuplicitNames(this.editSynonymNameNewVal)) {
        return this.$notify({
          group: 'app',
          type: 'error',
          title: 'Duplicit synonym name!',
          text: 'Synonym name must be unique',
        })
      }

      Vue.set(this.synonyms[this.editSynonymName], 'name', this.editSynonymNameNewVal)
      //   this.$emit("update:synonyms", this.synonyms);
      Vue.set(this, 'synonyms', this.synonyms)
      this.editSynonymName = undefined
    },
    checkDuplicitNames(name) {
      return this.synonyms.filter((x) => x.name == name).length
    },
    removeSynonymTemplate(k, templateId) {
      Vue.delete(this.synonyms[k].values, templateId)
    },
    addSynonymTemplate(k) {
      if (!this.$refs['new-synonym-' + k][0].value) {
        return
      }
      this.synonyms[k].values.unshift(this.$refs['new-synonym-' + k][0].value)
      this.$refs['new-synonym-' + k][0].value = ''
    },
  },
}
</script>
