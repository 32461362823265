<template>
  <div class="login-page">
    <b-container>
      <loading
        :active="loading"
        :is-full-page="true"
        background-color="#343a40"
        color="#f24516"
        :z-index="100000"
        :opacity="0.5"
        loader="dots"
        :width="120"
      ></loading>

      <h5 class="logo">
        <i class="fa fa-circle text-primary" />

        <i class="fa fa-circle text-secondary fa-lg" />
        <i class="fa fa-circle text-primary" />
        <br />
      </h5>
      <h2 class="logo mb-0">ChytreCallCentrum</h2>
      <div class="text-center mt-0"> by Happy Robots </div>
      <div class="mx-auto widget">
        <form class="mt" @submit.prevent="login" id="loginForm">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">{{ errorMessage }}</b-alert>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="email"
              required
              type="text"
              name="email"
              placeholder="Email"
              autocomplete="username"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control no-border"
              ref="passwd"
              required
              type="password"
              name="passwd"
              placeholder="Password"
              autocomplete="current-password"
            />
          </div>

          <div class="form-group d-flex" v-if="enableOTP">
            <input
              class="form-control no-border"
              ref="otp"
              required
              name="otp"
              placeholder="One time 6 digit auth code"
              type="text"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
            />

            <div>
              <i class="fa fa-question-circle m-2" v-b-toggle.collapse-otp-help></i>
            </div>
          </div>

          <b-collapse id="collapse-otp-help" class="my-3">
            <b-card>
              <p class="card-text"
                >One-Time-Password can be generated with auth app, for instance Google Authenticator -
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  >Download for Android</a
                >
                or <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a>. Then scan QR code from the
                inital login email from the CCC platform.</p
              >
              <p class="card-text">If you need help, please contact your CCC instance administrator.</p>
            </b-card>
          </b-collapse>

          <div class="clearfix">
            <div class="btn-toolbar float-left">
              <b-button id="reqaccess" type="reset" size="sm" variant="secondary" @click="showAcc = true"
                >Request Access</b-button
              >
            </div>
            <div class="btn-toolbar float-right">
              <b-button type="submit" size="sm" variant="primary">Login</b-button>
            </div>
          </div>
          <div class="clearfix mt-3" v-if="enableMSSSO">
            <div class="btn-toolbar">
              <b-button size="sm" variant="primary" @click="MSALSSORedirect">Corporate SSO</b-button>
            </div>
          </div>
          <div class="row no-gutters mt-3">
            <div class="abc-checkbox" v-show="showAcc">
              <b-alert show variant="info">
                <p>Please drop us email with a short description of Your use case.</p>
                Looking forward to hearing from You!
                <i class="fa fa-envelope ml-1"></i>
                <br />
                <a href="mailto://info@chytrecallcentrum.cz">info@chytrecallcentrum.cz</a>
              </b-alert>
            </div>
          </div>
        </form>
      </div>
    </b-container>
    <footer class="footer">
      Contact us
      <a href="mailto://info@chytrecallcentrum.cz">info@chytrecallcentrum.cz</a>
    </footer>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'LoginPage',
  components: { Loading },
  data() {
    return {
      errorMessage: null,
      showAcc: false,
      loading: false,
      enableMSSSO: ['True', 'true', '1', 1].includes(process.env.VUE_APP_MSAL_ENABLED),
      enableOTP: ['True', 'true', '1', 1].includes(process.env.VUE_APP_LOGIN_2FA_ENABLE_OTP),
    }
  },
  methods: {
    _auth_login(params) {
      this.loading = true
      this.$auth.login({
        data: params,
        params: {},
        success: function (data) {
          this.$root.initAll().then(() => {
            this.loading = false
          })
        },
        error: function (err) {
          if (err.response.status === 429) {
            this.errorMessage = 'Account suspended. Please try later.'
          } else {
            this.errorMessage = 'Login failed.'
          }
          this.loading = false
        },
        rememberMe: true,
        redirect: this.$store.state.pam.beforeLoginSite || '/app/dashboard',
        fetchUser: true,
      })
    },
    login() {
      const email = this.$refs.email.value.trim()
      const passwd = this.$refs.passwd.value.trim()
      const otp = this.enableOTP ? this.$refs.otp.value.trim() : ''

      if (this.$store.state.pam.beforeLoginSite == '/' || this.$store.state.pam.beforeLoginSite == '/login') {
        this.$store.state.pam.beforeLoginSite = undefined
      }

      this._auth_login({ email: email, passwd: passwd, otp: otp })
    },
    MSALSSORedirect() {
      this.loading = true
      this.axios.post('/v1/integrations/msal/sso-login').then((res) => {
        console.log(res.data)
        window.localStorage.setItem('msal_flow', JSON.stringify(res.data))
        window.location = res.data['msal_url']
      })
    },
    MASLLogin() {
      this.loading = true
      this.axios
        .post('/v1/integrations/msal/sso-cb' + location.search, JSON.parse(window.localStorage.getItem('msal_flow')))
        .then((res) => {
          window.history.replaceState(null, null, window.location.pathname) // remove ?param=value from url
          this._auth_login({ email: res.data.email, token: res.data.token })
        })
    },
    getURLParamsAsObj() {
      let search = location.search.substring(1)
      return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    },
  },
  created() {
    if (location.search.includes('code')) {
      // after SSO redirect
      this.MASLLogin()
    }
  },
}
</script>

<style src="./Login.scss" lang="scss" scoped />
