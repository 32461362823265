<template>
  <div>
    <!-- <b-button @click="$root.$emit('bv::show::modal', 'test-from-conversation-modal')">Click me</b-button> -->

    <b-modal
      v-if="tracker"
      ref="test-from-conversation-modal"
      id="test-from-conversation-modal"
      v-bind:title="'Create test from Conversation'"
      size="lg"
      @ok="createFunction"
      :key="'xxx'"
    >
      <b-alert :show="newTestName && !validateName" variant="danger">Duplicit test name</b-alert>

      <b-input-group class="mt-1">
        <b-input-group-prepend class="w-25">
          <b-input-group-text class="w-100">Test name</b-input-group-text>
        </b-input-group-prepend>
        <b-input v-model="newTestName"></b-input>
      </b-input-group>
      <b-form-text id="input-live-help" class="ml-3">New test name.</b-form-text>

      <b-input-group class="mt-1">
        <b-input-group-prepend class="w-25">
          <b-input-group-text class="w-100">Max reactions</b-input-group-text>
        </b-input-group-prepend>
        <b-input type="number" v-model="reactionsHistory"></b-input>
      </b-input-group>
      <b-form-text id="input-live-help" class="ml-3"
        >How many reactions should be at top included in one test step.</b-form-text
      >
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'ConversationToTest',
  props: { tracker: Array },
  computed: {
    ...mapFields('rest', ['tests']),
    ...mapGetters('rest', ['getReactionsByName']),
    validateName() {
      return !this.tests
        .map((x) => {
          return x.name
        })
        .includes(this.newTestName)
    },
  },
  data() {
    return {
      newTestName: null,
      reactionsHistory: 1,
    }
  },
  methods: {
    openModal() {
      this.$refs['test-from-conversation-modal'].show()
    },
    createFunction(event) {
      if (!this.newTestName) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Error!',
          text: 'Test name cannot be empty',
        })
        return event.preventDefault()
      }

      if (!this.validateName) {
        return event.preventDefault()
      }

      var reactions = []
      const defaultStep = {
        input: null,
        reactions: [],
        condition: '',
        actions: [],
      }

      var step = JSON.parse(JSON.stringify(defaultStep))
      var steps = []

      const addStep = () => {
        step.reactions = reactions.slice(0 - this.reactionsHistory)
        steps.push(JSON.parse(JSON.stringify(step)))
        reactions = []

        step = JSON.parse(JSON.stringify(defaultStep))
      }

      for (var log of this.tracker) {
        if (log.type == 'action' && log.value == 'reaction_scope_start') {
          let reaction = this.getReactionsByName[log.args[0]]
          if (reaction) {
            reactions.push(reaction.id)
          }
        }

        if (log.type == 'action' && log.value == 'conversation_transfer') {
          break
        }

        // if (log.type == "action" && log.value == "utter") {
        //   if (log.value == "utter") {
        //     console.log("robot", log.args[0]);
        //   }
        // }

        if (log.type == 'intent') {
          if (step.input) {
            addStep()
          }

          step.input = log.args.text
        }
      }

      addStep()

      var newTest = {
        name: this.newTestName,
        steps: steps,
      }

      const testIndex = this.tests.length
      this.tests.push(newTest)

      this.$router.push('Tests')

      this.$nextTick(() => {
        // wait till Test page ready
        this.$events.$emit('Tests:editTest', testIndex)

        this.$nextTick(() => {
          // avoid event loop freeze
          this.$store.dispatch('rest/insertTest', { data: newTest }).then((res) => {
            this.tests[testIndex].id = res.data.id
          })
        })
      })
    },
  },
}
</script>
