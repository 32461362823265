<template>
  <div class="card">
    <div class="card-header">
      <strong class="text-primary">Users logs </strong><span class="text-default">in last 2 months</span>
      <b-button variant="primary" size="sm" @click="downloadAsCSV()" class="float-right px-2">Download as CSV</b-button>
    </div>
    <div class="card-body">
      <div class="text-center">
        <div class="spinner-border text-primary" v-if="loading" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Action</th>
              <th>Logged at</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in usersLogs" :key="log.id">
              <td class="p-1">{{ log.id }}</td>
              <td class="p-1">{{ log.name }} {{ log.email }}</td>
              <td class="p-1">{{ log.action }}</td>
              <td class="p-1">{{ log.created_at | formatDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'UsersLogs',
  components: {},
  computed: {
    ...mapFields('rest', ['usersLogs']),
  },

  data() {
    return {
      loading: true,
    }
  },
  methods: {
    downloadAsCSV() {
      this.$store
        .dispatch('rest/exportUsersLogs', {
          params: { format: 'csv' },
        })
        .then((response) => {
          let url = window.URL.createObjectURL(response.data)
          var link = document.createElement('a')
          link.href = url
          link.download = 'usersLogs.csv'
          link.click()
        })
        .catch((e) => {
          console.log(e)
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot download file!',
          })
        })
    },
  },
  created: function () {
    this.$auth.apiReady().then(() => {
      this.$store.dispatch('rest/getUsersLogs', {}).then((this.loading = false))
    })
  },
  destroyed: function () {
    // free up space
    this.userLogs = []
  },
}
</script>
