<template>
  <b-modal class="deploymentModal" ref="modal" size="xl">
    <div slot="modal-title">
      <h4 v-if="!isRollback">
        Deploy
        <span v-if="env === 'prod'">to production</span>
        <span v-if="env === 'dev'">to testing</span>
      </h4>
      <h4 v-if="isRollback">Rollback to version {{ toDeploymentId }}</h4>
    </div>
    <div class="">
      <b-form @submit.prevent="deploy()">
        <b-form-group>
          <b-input-group>
            <b-textarea
              v-model="commitMessage"
              placeholder="Tell us something about this change..."
              ref="commitTextarea"
              id="commitTextarea"
              :state="formErrors.filter((e) => e.type === 'commitMessage').length === 0 ? undefined : false"
            />
            <b-form-invalid-feedback :key="error.message" v-for="error in formErrors.filter((e) => e.type === 'commitMessage')">
              {{ error.message }}
            </b-form-invalid-feedback>
          </b-input-group>
          <b-tooltip target="commitTextarea" triggers="hover">
            <small>Ctrl + Enter to deploy</small>
          </b-tooltip>
        </b-form-group>
        <b-form-group>
          <div class="d-flex" v-if="!loading">
            <div></div>
            <div class="ml-auto" v-if="shouldRunTests && !skipTestsAllowed && !isAllOk">
              <small v-if="isTestsRunning">There are still running tests! Please wait.</small>
              <small v-if="!isTestsRunning" class="text-danger"
                ><i class="fa fa-times-circle"></i> Some tests have failed.</small
              >
              <a href="" @click.prevent="skipTestsAllowed = true" class="d-inline-block ml-5">Deploy anyway</a>
            </div>
            <div class="ml-auto" v-if="!shouldRunTests || skipTestsAllowed || isAllOk">
              <b-button id="deployment-modal-deploy-btn" type="submit" variant="primary">Deploy</b-button>
            </div>
          </div>
          <div class="d-flex" v-else>
            <b-spinner variant="primary" label="Loading..." class="ml-auto mr-3"></b-spinner>
          </div>
        </b-form-group>

        <GlobalEvents @keydown.ctrl.enter.prevent="deploy()" />
      </b-form>
    </div>
    <div v-if="shouldRunTests" class="table-responsive">
      <h4>Tests</h4>
      <table class="table">
        <tr v-for="testResult in testsResults" :key="testResult.test.id">
          <td>
            <span class="text-success" v-if="testResult.status === 'ok'">{{ testResult.test.name }}</span>
            <span class="text-primary" v-if="testResult.status === 'failed'">{{ testResult.test.name }}</span>
            <span class="text-default" v-if="testResult.status === 'pending'">{{ testResult.test.name }}</span>
            <span class="text-default" v-if="testResult.status === 'running'">{{ testResult.test.name }}</span>
          </td>
          <td class="text-right">
            <span class="text-success" v-if="testResult.status === 'ok'">
              <i class="fa fa-check-circle"></i>
            </span>
            <span class="text-primary" v-if="testResult.status === 'failed'">
              <i class="fa fa-times-circle"></i>
            </span>
            <span class="ml-auto text-warning" v-if="testResult.status === 'pending'">
              <i class="fa fa-clock-o"></i>
            </span>
            <span class="ml-auto" v-if="testResult.status === 'running'">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
          </td>
        </tr>
      </table>
    </div>

    <div slot="modal-footer"></div>
  </b-modal>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

class TestResult {
  /** @type Object */
  test = null
  /** @type string - pending|running|failed|ok */
  status = 'pending'
  /** @type Promise */
  request = null
}

export default {
  name: 'DeploymentModal',
  components: {},
  computed: {
    ...mapFields('rest', ['deployments', 'tests']),
  },
  data() {
    return {
      formErrors: [],
      env: '',
      commitMessage: '',
      shouldRunTests: true,
      toDeploymentId: null,
      isRollback: false,
      skipTestsAllowed: false,
      isTestsRunning: false,
      isAllOk: false,
      /** @type {TestResult[]} */
      testsResults: [],
      loading: false,
    }
  },
  methods: {
    startDeployment(env = 'prod', toDeploymentId = null) {
      this.env = env
      this.toDeploymentId = toDeploymentId
      this.isRollback = toDeploymentId !== null
      if (this.isRollback) {
        this.commitMessage = 'Rollback to version ' + toDeploymentId
      }
      this.formErrors = []
      this.skipTestsAllowed = false
      this.shouldRunTests = env === 'prod' && !this.isRollback && this.tests.length > 0
      this.isTestsRunning = this.shouldRunTests
      this.isAllOk = false
      this.testsResults = []
      this.$refs['modal'].show()
      if (this.shouldRunTests) {
        this.runTests()
          .then(() => {
            this.isAllOk = true
          })
          .catch((e) => {
            this.isAllOk = false
          })
          .finally(() => {
            this.isTestsRunning = false
          })
      }
    },
    deploy() {
      this.validateForm()
      if (this.formErrors.length > 0) {
        return
      }
      let params = { project_env: this.env, message: this.commitMessage }
      if (this.isRollback) {
        params.toDeploymentId = this.toDeploymentId
      }
      this.loading = true
      this.$store
        .dispatch('rest/runDeploy', { params: params })
        .then((response) => {
          this.deployments.unshift(response.data)
          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Successfully deployed',
          })
          this.commitMessage = ''
          this.$refs['modal'].hide()
        })
        .catch(() => {
          this.$notify({
            group: 'app',
            type: 'danger',
            title: 'Deploy failed - something went wrong!',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    validateForm() {
      this.formErrors = []
      if (this.env === 'prod' && (!this.commitMessage || this.commitMessage.length < 3)) {
        this.formErrors.push({
          type: 'commitMessage',
          message: 'Please describe done changes briefly.',
        })
        this.$refs['commitTextarea'].focus()
      }
    },
    /**
     * @returns {Promise} when all tests are done
     */
    runTests() {
      for (let test of this.tests) {
        let testResult = new TestResult()
        testResult.test = test
        testResult.status = 'running'
        testResult.promise = new Promise((resolve, reject) => {
          this.$store
            .dispatch('rest/runTest', { params: { id: test.id } })
            .then((res) => {
              if (res.data.result === 'ok') {
                testResult.status = 'ok'
                resolve(testResult)
              } else {
                testResult.status = 'failed'
                reject(testResult)
              }
            })
            .catch((_) => {
              testResult.status = 'failed'
              reject(testResult)
            })
        })
        this.testsResults.push(testResult)
      }
      return Promise.all(this.testsResults.map((result) => result.promise))
    },
  },
  watch: {},
}
</script>
