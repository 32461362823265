<template>
  <div v-if="checkPermissions('/history', 'GET')">
    <b-row>
      <div class="card shadow mb-4 w-100">
        <div class="card-header d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Compare changes</h6>
        </div>

        <div class="card-body">
          <b-row>
            <b-col
              ><h5>Left</h5>
              Use deployment:

              <b-form-select
                v-model="selectedDeploymentLeft"
                class="mb-1"
                @change="
                  selectedDateLeft = null
                  selectedTimeLeft = null
                "
              >
                <b-form-select-option :value="null">--- Select Deployment or datetime ---</b-form-select-option>
                <b-form-select-option :value="d.created" v-for="d in deployments" :key="d.created">
                  {{ d.project_env }}, {{ d.created | formatDate }}, {{ d.user_id }}, {{ findUserById(d.users_id).name }},
                  {{ d.note }}
                </b-form-select-option>
              </b-form-select>

              <label for="example-input">Or choose a date and time:</label>
              <b-input-group class="mb-3">
                <b-form-input
                  id="example-input"
                  v-model="selectedDateLeft"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  @change="selectedDeploymentLeft = null"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="selectedDateLeft"
                    button-only
                    right
                    locale="en-US"
                    aria-controls="example-input"
                    @input="selectedDeploymentLeft = null"
                  ></b-form-datepicker>
                </b-input-group-append>

                <b-form-input
                  id="example-input"
                  v-model="selectedTimeLeft"
                  type="text"
                  placeholder="HH:MM:ss"
                  autocomplete="off"
                  class="ml-1"
                  @change="selectedDeploymentLeft = null"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="selectedTimeLeft"
                    button-only
                    show-seconds
                    h23
                    right
                    :hour12="false"
                    aria-controls="example-input"
                    @input="selectedDeploymentLeft = null"
                  ></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col
              ><h5>Right</h5>
              Use deployment:

              <!-- {{ deployments }} -->
              <b-form-select
                v-model="selectedDeploymentRight"
                class="mb-1"
                @change="
                  selectedDateRight = null
                  selectedTimeRight = null
                "
              >
                <b-form-select-option :value="null">--- Select Deployment or datetime ---</b-form-select-option>
                <b-form-select-option :value="d.created" v-for="d in deployments" :key="d.created">
                  {{ d.project_env }}, {{ d.created | formatDate }}, {{ d.user_id }}, {{ findUserById(d.users_id).name }},
                  {{ d.note }}
                </b-form-select-option>
              </b-form-select>

              <label for="example-input">Or choose a date and time:</label>
              <b-input-group class="mb-3">
                <b-form-input
                  id="example-input"
                  v-model="selectedDateRight"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  @change="selectedDeploymentRight = null"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="selectedDateRight"
                    button-only
                    right
                    locale="en-US"
                    aria-controls="example-input"
                    @input="selectedDeploymentRight = null"
                  ></b-form-datepicker>
                </b-input-group-append>

                <b-form-input
                  id="example-input"
                  v-model="selectedTimeRight"
                  type="text"
                  placeholder="HH:MM:ss"
                  autocomplete="off"
                  class="ml-1"
                  @change="selectedDeploymentRight = null"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="selectedTimeRight"
                    button-only
                    show-seconds
                    h23
                    right
                    :hour12="false"
                    aria-controls="example-input"
                    @input="selectedDeploymentRight = null"
                  ></b-form-timepicker>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-left">
              Showing:<br />
              <span v-if="!mtimeLeft">Current</span>
              <span v-else> {{ (mtimeLeft / 1e6) | formatDate }} UTC</span>
            </b-col>
            <b-col class="cols-auto">
              <div class="text-center"> <b-button variant="primary" @click="compare">Show Changes</b-button> </div>
            </b-col>
            <b-col class="text-right">
              Showing:<br />
              <span v-if="!mtimeRight">Current</span>
              <span v-else>{{ (mtimeRight / 1e6) | formatDate }} UTC</span>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-row>

    <b-card no-body>
      <b-tabs card>
        <b-tab v-for="resource in resources" :key="'resource-' + resource">
          <template slot="title">
            <span class="text-capitalize mr-2">{{ resource }}</span>

            <b-badge :variant="_.size(diffs[resource]) > 0 ? 'primary' : 'secondary'">{{ _.size(diffs[resource]) }}</b-badge>
          </template>
          <b-card-text>
            <div class="diff-reactions">
              <b-button
                @click="toggleShowAll(resource)"
                size="sm"
                class="float-right"
                style="margin-top: -0.75rem"
                v-if="!_.isEmpty(diffs[resource])"
                >{{ showAll[resource]['____all'] ? 'Hide All' : 'Show All' }}</b-button
              >
              <b-button
                @click="
                  showAudit = !showAudit
                  compare()
                "
                size="sm"
                class="float-right mx-2"
                style="margin-top: -0.75rem"
                v-if="!_.isEmpty(diffs[resource]) && checkPermissions('/autdit', 'GET')"
                >{{ showAudit ? 'Hide Audit' : 'Show Audit' }}</b-button
              >
              <div v-for="(diff, name) in diffs[resource]" :key="resource + '-diff-' + name">
                <i
                  class="fa mx-2 btn p-0"
                  :class="{ 'fa-eye': !showAll[resource][name], 'fa-eye-slash': showAll[resource][name] }"
                  @click="toggleShow(resource, name)"
                >
                  <b class="ml-2">{{ name }}</b></i
                >
                <lazy-component wrapper-tag="div" v-show="showAll[resource][name]">
                  <code-diff :old-string="diff.left" :new-string="diff.right" :fileName="name" output-format="side-by-side" />

                  <div slot="placeholder" style="height: 100px">Loading diff...</div>
                </lazy-component>
              </div>
              <div v-if="_.isEmpty(diffs[resource])"> Nothing to show </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import LazyComponent from 'v-lazy-component'
import moment from 'moment'

export default {
  name: 'History',
  components: {
    CodeDiff: () => import('vue-code-diff'),
    LazyComponent,
  },
  computed: {
    ...mapFields('rest', [
      'intents',
      'reactions',
      'actions',
      'entities',
      'deployments',
      'reactionsScopes',
      'settings',
      'auditDeleted',
    ]),
    ...mapGetters('rest', ['getFormattedReactionCondition', 'findUserById']),
    reactionsScopesById() {
      var scopes = {}
      for (var s of this.reactionsScopes) {
        scopes[s.id] = s
      }
      return scopes
    },
  },
  data() {
    return {
      mtimeLeft: null,
      mtimeRight: null,
      selectedDeploymentLeft: null,
      selectedDeploymentRight: null,
      selectedDateLeft: null,
      selectedDateRight: null,
      selectedTimeLeft: null,
      selectedTimeRight: null,
      resources: ['reactions', 'intents', 'actions', 'entities', 'settings', 'knowledges'],
      diffs: {},
      showAll: {},
      showAudit: true,
    }
  },
  created() {
    this.resources = this.resources.filter((r) => {
      return this.checkPermissions('/' + r, 'GET')
    })
    this.resources.map((x) => {
      this.showAll[x] = {}
    })
  },
  methods: {
    compare() {
      // mtimeLeft
      if (this.selectedDeploymentLeft) {
        this.mtimeLeft = this.selectedDeploymentLeft
      } else {
        if (this.selectedDateLeft && this.selectedTimeLeft) {
          this.mtimeLeft = moment(this.selectedDateLeft + ' ' + this.selectedTimeLeft, 'YYYY-MM-DD hh:mm:ss').unix()
        } else if (this.selectedDateLeft) {
          this.mtimeLeft = moment(this.selectedDateLeft + ' 00:00:00', 'YYYY-MM-DD hh:mm:ss').unix()
        } else {
          this.mtimeLeft = null
        }
      }
      if (this.mtimeLeft) {
        this.mtimeLeft = this.mtimeLeft * 1000000 + 999999 // max microseconds
      }

      // mtimeRight
      if (this.selectedDeploymentRight) {
        this.mtimeRight = this.selectedDeploymentRight
      } else {
        if (this.selectedDateRight && this.selectedTimeRight) {
          this.mtimeRight = moment(this.selectedDateRight + ' ' + this.selectedTimeRight, 'YYYY-MM-DD hh:mm:ss').unix()
        } else if (this.selectedDateRight) {
          this.mtimeRight = moment(this.selectedDateRight + ' 00:00:00', 'YYYY-MM-DD hh:mm:ss').unix()
        } else {
          this.mtimeRight = null
        }
      }
      if (this.mtimeRight) {
        this.mtimeRight = this.mtimeRight * 1000000 + 999999 // max microseconds
      }

      let auditPromise = new Promise((resolve) => {
        if (this.showAudit) {
          this.$store
            .dispatch('rest/getAuditDeleted', { params: { mtime: Math.min(this.mtimeLeft, this.mtimeRight) } })
            .then(() => {
              resolve()
            })
        } else {
          resolve()
        }
      })

      this.resources.map((x) => {
        this.diffResource(x, auditPromise)
      })
    },
    arrayOfObjectsByName(arr) {
      return arr.reduce((obj, item) => ((obj[item.name] = item), obj), {})
    },
    loadResource(apiPath, mtime, current) {
      return new Promise((resolve, reject) => {
        // we want to fetch current as well because we need to refresh metadata (who and version)
        // if we'd fetch metadata with resource update we could skip fetching current resource from the server
        let params = mtime ? { mtime: mtime } : {}
        this.axios.get(apiPath, { params: params }).then((response) => {
          resolve(response.data)
        })
      })
    },
    diffResource(resource, auditPromise) {
      let l = this.loadResource('/v1/' + resource, this.mtimeLeft, this[resource])
      let r = this.loadResource('/v1/' + resource, this.mtimeRight, this[resource])

      Promise.all([l, r, auditPromise]).then((values) => {
        if (resource == 'settings') {
          // settings do not have nested structure, hence special one-level diff
          let leftSerialized = this.serializeSettings(values[0])
          let rightSerialized = this.serializeSettings(values[1])

          if (leftSerialized != rightSerialized) {
            this.$set(this.diffs, resource, {
              settings: { left: leftSerialized, right: rightSerialized },
            })
          }
          this.$set(this.showAll[resource], '____all', true)
          this.$set(this.showAll[resource], 'settings', true)
          return
        }
        const diff = this.diffVersions(
          this.arrayOfObjectsByName(values[0]),
          this.arrayOfObjectsByName(values[1]),
          this.serializeResource(resource),
          this.getAuditDeletedMsg(resource)
        )
        this.$set(this.diffs, resource, diff)

        this.$set(this.showAll[resource], '____all', true)
        for (const name in this.diffs[resource]) {
          this.$set(this.showAll[resource], name, true)
        }
      })
    },
    diffVersions(leftVersions, rightVersions, serializationFn, auditDeleteMsg) {
      let diff = {}
      for (const name in leftVersions) {
        if (!rightVersions[name]) {
          diff[name] = { right: auditDeleteMsg(name), left: serializationFn(leftVersions[name]) }
        } else {
          let right = serializationFn(rightVersions[name])
          let left = serializationFn(leftVersions[name])

          if (right != left) {
            diff[name] = { right: right, left: left }
          }
        }
      }

      for (const name in rightVersions) {
        if (!leftVersions[name]) {
          diff[name] = { right: serializationFn(rightVersions[name]), left: auditDeleteMsg(name) }
        }
      }
      return diff
    },
    serializeResource(type) {
      return (value) => {
        switch (type) {
          case 'reactions':
            return this.serializeReaction(value)
          case 'intents':
            return this.serializeIntents(value)
          case 'entities':
            return this.serializeEntities(value)
          case 'actions':
            return this.serializeAction(value)
          case 'settings':
            return this.serializeSettings(value)
          case 'knowledges':
            return this.serializeKnowledges(value)
        }
      }
    },
    serializeReaction(reaction) {
      let oa =
        '\n' +
        reaction.actions
          .map((a) => {
            return '    ' + a.name + ':\t' + a.args.join(', ') + (this.showAudit ? '\t## ' + this.findUserById(a.who).name : '')
          })
          .join('\n') +
        '\n'

      let scope = reaction.reactions_scopes_id ? `\nScope: ${this.reactionsScopesById[reaction.reactions_scopes_id].name}` : ''

      return (
        `Name:      ${reaction.name}
Condition: ${this.getFormattedReactionCondition(reaction.condition)}
Intents:   ${reaction.intents.join(', ')}${scope}
Priority Class: ${reaction.priority}
Actions: ${oa}` + this.serializeAudit(reaction)
      )
    },
    serializeAction(action) {
      return action.source + this.serializeAudit(action)
    },
    serializeIntents(intent) {
      return intent.templates.join('\n') + '\n' + this.serializeAudit(intent)
    },
    serializeEntities(entity) {
      let values =
        entity.values
          .split('\n')
          .map((v) => {
            return '    ' + v
          })
          .join('\n') + '\n'
      return (
        `Type: ${entity.type}
Occurence: ${entity.occurence}
Match: ${entity.match}
Values:
${values}` + this.serializeAudit(entity)
      )
    },
    serializeSettings(settings) {
      if (!settings) {
        return 'N/A'
      }
      return (
        JSON.stringify(
          _.pick(settings, [
            'name',
            'no_instances_testing',
            'no_instances_production',
            'context_phrases',
            'confidence_threshold',
            'voices_id',
            'voice_pitch',
            'voice_speed',
            'long_silence_utter',
            'long_silence_timeout',
            'long_silence_retries',
            'long_silence_hangup_utter',
            'sip_server_testing',
            'sip_number_testing',
            'sip_passwd_testing',
            'sip_server_production',
            'sip_number_production',
            'sip_passwd_production',
            'stt_corrections',
            'is_chat',
            'stt_provider',
            'language',
            'autocorrect_threshold',
            'chatbot_client_scss',
            'chatbot_client_js',
            'config_struct',
            'config_dev',
            'config_prod',
            'multi_intent',
            'chatbot_base_url',
            'chatbot_token',
            'conversations_retention',
          ]),
          null,
          2
        ) + this.serializeAudit(settings)
      )
    },
    serializeKnowledges(knowledge) {
      return 'Link: ' + knowledge.link + '\nKnowledge:\n' + knowledge.knowledge + '\n' + this.serializeAudit(knowledge)
    },
    serializeAudit(resource) {
      if (!this.showAudit) {
        return ''
      }

      let time = moment(resource.version * 1000).format()
      return `\nLast modified by: ${this.findUserById(resource.who).name}\nModify time: ${time}`
    },
    getAuditDeletedMsg(resourceType) {
      return (resource) => {
        if (!this.showAudit) {
          return ''
        }

        let r = this.auditDeleted[resourceType][resource]
        if (!r) {
          return ''
        }

        let time = moment(r.version * 1000).format()
        return `\nDeleted by: ${this.findUserById(r.who).name}\nDeleted time: ${time}`
      }
    },
    toggleShowAll(type) {
      let ____all = !this.showAll[type]['____all']
      for (const key in this.showAll[type]) {
        this.$set(this.showAll[type], key, ____all)
      }
      this.$forceUpdate() // overcome vue multidimensional (in)reactivity
    },
    toggleShow(type, key) {
      this.$set(this.showAll[type], key, !this.showAll[type][key])
      this.$forceUpdate() // overcome vue multidimensional (in)reactivity
    },
  },
}
</script>
<style>
/* fix github.com/ddchef/vue-code-diff/issues/58 */
.d2h-wrapper .d2h-code-side-line,
.d2h-wrapper .d2h-code-line {
  display: inline-block;
  width: auto;
}

.d2h-info {
  display: none;
}

.diff-reactions .d2h-code-side-linenumber {
  display: none;
}

.diff-reactions .d2h-code-side-line {
  padding-left: 5px;
}

.diff-intents .d2h-code-side-linenumber {
  display: none;
}

.diff-intents .d2h-code-side-line {
  padding-left: 5px;
}

.d2h-file-side-diff {
  overflow-x: auto;
}
</style>
