import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/components/Layout/Layout'

import Roles from '@/pages/Roles/Roles'
import ErrorPage from '@/pages/Error/Error'
import Login from '@/pages/Login/Login'
import UsersLogs from '@/pages/UsersLogs/UsersLogs'

import StoragePage from '@/pages/Storage/Storage'
import TestsPage from '@/pages/Tests/Tests'
import FallbacksPage from '@/pages/Fallbacks/Fallbacks'
import IntentsPage from '@/pages/Intents/Intents'
import EntitiesPage from '@/pages/Entities/Entities'
import Deployments from '@/pages/Deployments/Deployments'
import ChatbotTest from '@/components/ChatbotTest/ChatbotTest'
import Outbound from '@/pages/Outbound/Outbound'
import History from '@/pages/History/History'

// lazy import
const GraphEditorPage = () => import('@/pages/GraphEditor/GraphEditor')
const SettingsPage = () => import('@/pages/Settings/Settings')
const ActionsPage = () => import('@/pages/Actions/Actions')
const PlatformSettings = () => import('@/pages/PlatformSettings/PlatformSettings')
const DashboardPage = () => import('@/pages/Dashboard/Dashboard')
const ConversationsPage = () => import('@/pages/Conversations/Conversations')
const KnowledgesPage = () => import('@/pages/Knowledges/Knowledges')
const QATrainingsPage = () => import('@/pages/QATrainings/QATrainings')

Vue.use(Router)

export default new Router({
  // mode: 'history',
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect: '/app/dashboard',
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage,
    },
    {
      path: '/app',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: DashboardPage,
        },
        {
          path: 'grapheditor',
          name: 'Graph Editor',
          component: GraphEditorPage,
        },
        {
          path: 'actions',
          name: 'Actions',
          component: ActionsPage,
        },
        {
          path: 'storage',
          name: 'Storage',
          component: StoragePage,
        },
        {
          path: 'logs',
          name: 'Conversations',
          component: ConversationsPage,
        },
        {
          path: 'tests',
          name: 'Tests',
          component: TestsPage,
        },
        {
          path: 'fallbacks',
          name: 'Fallbacks',
          component: FallbacksPage,
        },
        {
          path: 'intents',
          name: 'Intents',
          component: IntentsPage,
        },
        {
          path: 'entities',
          name: 'Entities',
          component: EntitiesPage,
        },
        {
          path: 'settings',
          name: 'Settings',
          component: SettingsPage,
        },
        {
          path: 'platform-settings',
          name: 'Platform settings',
          component: PlatformSettings,
        },
        {
          path: 'users-logs',
          name: 'Users logs',
          component: UsersLogs,
        },
        {
          path: 'deployments',
          name: 'Deployments',
          component: Deployments,
        },
        {
          path: 'chatbot-test',
          name: 'ChatbotTest',
          component: ChatbotTest,
        },
        {
          path: 'roles',
          name: 'User Roles',
          component: Roles,
        },
        {
          path: 'outbound',
          name: 'Outbound',
          component: Outbound,
        },
        {
          path: 'history',
          name: 'History',
          component: History,
        },
        {
          path: 'knowledge',
          name: 'Knowledge base',
          component: KnowledgesPage,
        },
        {
          path: 'qa-trainings',
          name: 'QA Trainings',
          component: QATrainingsPage,
        },
      ],
    },
    {
      path: '*',
      component: ErrorPage,
    },
  ],
})
