<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Deployments</h6>
        <div class="ml-auto">
          <button
            class="btn btn-secondary btn-sm"
            @click.prevent="$refs['deploymentModal'].startDeployment('dev')"
            v-if="checkPermissions('/run/deploy', 'GET', 'dev')"
          >
            <i class="fa fa-vial"></i>
            Deploy to testing
          </button>

          <button
            class="btn btn-primary btn-sm ml-1"
            @click.prevent="$refs['deploymentModal'].startDeployment('prod')"
            v-if="checkPermissions('/run/deploy', 'GET', 'prod')"
          >
            <i class="fa fa-upload"></i>
            Deploy to production
          </button>
        </div>
      </div>
      <div class="card-body">
        <!-- <p>These are finished calls here. And some metadata.</p> -->
        <div class="d-flex mb-2">
          <div>
            <label for="versionFilterFrom">Version</label>
            <b-input-group size="sm" class="mb-1">
              <b-input-group-prepend class="w-25">
                <b-input-group-text class="w-100">From</b-input-group-text>
              </b-input-group-prepend>
              <date-pick id="versionFilterFrom" class="form-control" v-model="filters.versionFrom" />
            </b-input-group>

            <b-input-group size="sm">
              <b-input-group-prepend class="w-25">
                <b-input-group-text class="w-100">To</b-input-group-text>
              </b-input-group-prepend>
              <date-pick id="versionFilterTo" class="form-control" v-model="filters.versionTo" />
            </b-input-group>
          </div>

          <div class="ml-1">
            <label for="createdFilterFrom">Created</label>
            <b-input-group size="sm" class="mb-1">
              <b-input-group-prepend class="w-25">
                <b-input-group-text class="w-100">From</b-input-group-text>
              </b-input-group-prepend>
              <date-pick id="createdFilterFrom" class="form-control" v-model="filters.createdFrom" />
            </b-input-group>

            <b-input-group size="sm">
              <b-input-group-prepend class="w-25">
                <b-input-group-text class="w-100">To</b-input-group-text>
              </b-input-group-prepend>
              <date-pick id="createdFilterTo" class="form-control" v-model="filters.createdTo" />
            </b-input-group>
          </div>

          <div class="ml-1">
            <div class="form-group">
              <label for="filterBy">By user</label>
              <input class="form-control form-control-sm" id="filterBy" v-model="filters.username" />
            </div>
          </div>

          <div class="ml-5">
            <div class="form-group">
              <b-form-group label="Environment">
                <b-form-radio v-model="filters.env" name="envRadios" value="dev">Testing</b-form-radio>
                <b-form-radio v-model="filters.env" name="envRadios" value="prod">Production</b-form-radio>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>Version</th>
                <th>Deployed</th>
                <th>By user</th>
                <th>Env</th>
                <th colspan="4">Note</th>
                <th>Rollback</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="this.deployments.filter(this.applyFilters).length === 0">
                <td colspan="42">No match.</td>
              </tr>
              <tr v-for="deployment in this.deployments.filter(this.applyFilters)" :key="deployment.id">
                <td>{{ deployment.id }}</td>
                <td>{{ deployment.version | formatDate }}</td>
                <td>{{ deployment.created | formatDate }}</td>
                <td>{{ findUserById(deployment.users_id).name }}</td>
                <td>{{ deployment.project_env }}</td>
                <td colspan="4">
                  <pre>{{ deployment.note }}</pre>
                </td>
                <td>
                  <a
                    href=""
                    v-if="deployment.project_env === 'prod'"
                    @click.prevent="$refs['deploymentModal'].startDeployment(deployment.project_env, deployment.id)"
                    ><small>rollback here</small></a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <DeploymentModal ref="deploymentModal"></DeploymentModal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import './Deployments.scss'
import DatePick from 'vue-date-pick'
import moment from 'moment'
import DeploymentModal from '@/components/DeploymentModal/DeploymentModal'

export default {
  name: 'Deployments',
  components: { DeploymentModal, DatePick },
  computed: {
    ...mapFields('rest', ['deployments', 'users']),
    ...mapGetters('rest', ['findUserById']),
  },

  /** @type { id, companies_id } user */

  data() {
    return {
      versionFrom: moment().format('YYYY-MM-DD'),
      filters: {
        env: 'prod',
      },
    }
  },
  methods: {
    applyFilters(deployment) {
      let user = this.users.find((u) => u.id === deployment.users_id)
      let username = user ? user.name : 'N/A'
      if (this.filters.username && this.filters.username.length > 0 && !username.includes(this.filters.username)) {
        return false
      }
      let oneDay = 3600 * 24 // for matching entire day despite the time
      if (this.filters.versionFrom && deployment.version < Date.parse(this.filters.versionFrom) / 1000) {
        return false
      }
      if (this.filters.versionTo && deployment.version > Date.parse(this.filters.versionTo) / 1000 + oneDay) {
        return false
      }
      if (this.filters.createdFrom && deployment.created < Date.parse(this.filters.createdFrom) / 1000) {
        return false
      }
      if (this.filters.createdTo && deployment.created > Date.parse(this.filters.createdTo) / 1000 + oneDay) {
        return false
      }
      if (this.filters.env && this.filters.env !== deployment.project_env) {
        return false
      }

      return true
    },
  },
}
</script>
