<template>
  <div>
    <div class="card shadow mb-4">
      <!-- Card Header - Dropdown -->
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Entities</h6>
        <a href="/docs/entities/" target="_blank" class="float-right">
          Documentation
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>

      <div class="card-body">
        <div class="m-2" v-if="checkPermissions('/entities/<id:int>', 'PUT')">
          <div v-if="newEntityErr" class="alert alert-danger p-1" role="alert">{{ newEntityErr }}</div>
          <label for="AddEntity">Add new Entity</label>
          <input
            id="AddEntity"
            v-model="newEntityName"
            placeholder="New Entity Identifier"
            @keypress.enter="addNewEntity()"
            class="rounded ml-2"
          />
          <b-button @click="addNewEntity()" size="sm" variant="primary" class="ml-3 box-shadow-2">Add Entity</b-button>
        </div>

        <b-list-group class="col-10">
          <b-list-group-item v-for="(entity, i) in entities" :key="i" class="bg-gradient-top-light">
            <b-row class="my-2">
              <b-col cols="2">
                <div class="mt-1">Entity name</div>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  :id="'input-' + i"
                  type="text"
                  size="sm"
                  v-model="entities[i].name"
                  required
                  placeholder="Entity identifier"
                  :disabled="!canEdit[i]"
                ></b-form-input>
              </b-col>
              <b-col cols="7" class="float-right">
                <div class="float-right">
                  <div :id="'delete-' + i" class="mt-1 float-right" @click="deleteEntity(i)" v-if="canEdit[i]">
                    <i class="fa fa-trash-alt"></i>
                  </div>
                  <span v-if="!canEdit[i]" class="my-auto text-muted"> System entity, insufficient permisssions to edit.</span>
                </div>
              </b-col>
            </b-row>

            <b-form-radio-group
              v-model="entities[i].type"
              :id="'entity-type-' + i"
              class="my-1 bg-gray-100"
              :disabled="!canEdit[i]"
            >
              <b-row>
                <b-col cols="3">Type</b-col>
                <b-col>
                  <b-form-radio value="dictionary">Dictionary</b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio value="number">Number</b-form-radio>
                </b-col>
                <b-col>
                  <!-- <b-form-radio value="regexp" disabled>Reg exp</b-form-radio> -->
                </b-col>
              </b-row>
            </b-form-radio-group>

            <b-form-radio-group
              v-if="entities[i].type == 'dictionary'"
              v-model="entities[i].match"
              :id="'entity-match-' + i"
              class="my-1"
              :disabled="!canEdit[i]"
            >
              <b-row>
                <b-col cols="3">
                  <span class="ml-3">Match Mode</span>
                </b-col>
                <b-col>
                  <b-form-radio value="exact">Exact</b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio value="lemmatized">Lemmatized</b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio value="fuzzy">Fuzzy</b-form-radio>
                </b-col>
              </b-row>
            </b-form-radio-group>

            <b-form-radio-group
              v-model="entities[i].occurrence"
              :id="'entity-occurrence-' + i"
              class="my-1"
              :disabled="!canEdit[i]"
            >
              <b-row>
                <b-col cols="3">Occurrence</b-col>
                <b-col>
                  <b-form-radio value="single">Force single</b-form-radio>
                </b-col>
                <b-col>
                  <b-form-radio value="multiple">Multiple</b-form-radio>
                </b-col>
                <b-col></b-col>
              </b-row>
            </b-form-radio-group>

            <div v-if="entities[i].type == 'dictionary'" class="my-1 bg-gray-100">
              <b-row>
                <b-col cols="3">Is case insensitive</b-col>
                <b-col>
                  <b-form-checkbox v-model="entities[i].case_insensitive" :disabled="!canEdit[i]"> </b-form-checkbox>
                </b-col>
                <b-col> </b-col>
                <b-col></b-col>
              </b-row>
            </div>

            <div v-if="checkPermissions('/system/entities/<id:int>', 'PUT')">
              <b-row>
                <b-col cols="3">Is system entity</b-col>
                <b-col>
                  <b-form-checkbox v-model="entities[i].__is_system__" @change="isSystemChanged[i] = true"> </b-form-checkbox>
                </b-col>
                <b-col> </b-col>
                <b-col></b-col>
                <small class="mr-3">System entities are available for all projects within the platform</small>
              </b-row>
              <b-alert :show="!entities[i].__is_system__ && isSystemChanged[i] != undefined" variant="danger">
                Marking entity <b>non-system</b> will hide it from <b>all</b> the projects within the platform!
              </b-alert>
            </div>

            <div v-if="entities[i].type == 'dictionary'" class="mt-2">
              <h6 class="d-inline">Comma or new line separated values</h6>
              <textarea-autosize
                v-model="entities[i].values"
                class="col p-1 form-control"
                :min-height="60"
                :max-height="350"
                :disabled="!canEdit[i]"
              />

              <b-alert variant="warning" :show="entities[i].match == 'fuzzy' && showFuzzyAlert(i)" class="mt-2">
                Important: For Fuzzy matching to work correctly (i.e. to be able to generalize) use at least 20 examples and tag
                <b>all</b> the occurences of the entity in intent templates!
              </b-alert>
            </div>

            <!-- <div v-if="entities[i].type == 'regexp'">
              <h6 class="d-inline">Regular expression</h6>
              <b-input class="bg-gray-100" />
            </div>-->
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <PageSave :firstAction="updateEntities" :secondAction="train" secondButtonText="Save & Train" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields'
import PageSave from '@/components/PageSave/PageSave'

export default {
  name: 'Entities',
  components: { PageSave },
  computed: {
    ...mapFields('rest', ['entities', 'entitiesOriginal']),
    canEdit() {
      return this.entities.map((x) => !x.__is_system__ || this.checkPermissions('/system/entities/<id:int>', 'PUT'))
    },
  },
  data() {
    return {
      newEntityErr: '',
      newEntityName: '',
      isSystemChanged: {},
    }
  },
  methods: {
    checkDuplicitNames() {
      for (var e in this.entities) {
        if (e.name == this.newEntityName) {
          return true
        }
      }
      return false
    },
    containNonAsciiChars(str) {
      var ascii = /^[ -~]+$/
      return !ascii.test(str)
    },
    addNewEntity() {
      if (!this.newEntityName) {
        this.newEntityErr = 'Entity name cannot be empty'
        return
      }

      if (this.checkDuplicitNames(this.newEntityName)) {
        this.newEntityErr = 'Duplicit entity name'
        return
      }

      if (this.newEntityName.indexOf(' ') != -1) {
        this.newEntityErr = 'Entity name cannot contain spaces'
        return
      }

      if (this.containNonAsciiChars(this.newEntityName)) {
        // string has non-ascii characters
        this.newEntityErr = 'Entity name can contain only ASCII characters (i.e. no diacritics)'
        return
      }

      var newEntity = {
        name: this.newEntityName,
        type: 'dictionary',
        occurrence: 'single',
        match: 'lemmatized',
        case_insensitive: false,
        __is_system__: false,
      }

      this.$store.dispatch('rest/insertEntity', { data: newEntity }).then((x) => {
        newEntity.id = x.data.id
        this.entities.unshift(newEntity)

        this.entitiesOriginal[this.newEntityErr] = JSON.stringify(newEntity)

        this.newEntityName = ''
        this.newEntityErr = ''
      })
    },
    deleteEntity(index) {
      this.$bvModal
        .msgBoxConfirm('Do You really want to remove Entity?', {
          title: 'Remove entity ' + this.entities[index].name,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            if (this.entities[index].id) {
              this.$store
                .dispatch('rest/deleteEntity', {
                  params: { id: this.entities[index].id },
                })
                .then(() => {
                  this.$store.dispatch('rest/reloadWorker')
                })
              this.entities.splice(index, 1)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateEntities() {
      for (const e of this.entities) {
        if (this.containNonAsciiChars(e.name) || /[\.\$\,\ ]/.test(e.name)) {
          this.newEntityErr =
            'Entity (' +
            e.name +
            ') name can contain only ASCII characters (i.e. no diacritics) except special characters $ . , etc.'
          return Promise.reject(this.newEntityErr)
        }
      }

      var promises = [Promise.resolve()]
      for (const e of this.entities) {
        if (JSON.stringify(e) != this.entitiesOriginal[e.name]) {
          promises.push(
            this.$store.dispatch('rest/updateEntity', { params: { id: e.id }, data: e }).then(() => {
              this.entitiesOriginal[e.name] = JSON.stringify(e)
            })
          )
        }
      }

      return Promise.all(promises)
        .then(() => {
          this.$store.dispatch('rest/reloadWorker').then(() => {
            return Promise.resolve(true)
          })
        })
        .then(() => {
          // @TODO call notify from here not from PageSave
          // this.$notify({
          //   group: "app",
          //   type: "success",
          //   title: "Updated"
          // });
        })
    },
    train() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('rest/runTrain')
          .then((x) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: 'Successfully trained',
            })
          })
          .finally(() => {
            resolve()
          })
      })
    },
    showFuzzyAlert(index) {
      return this.entities[index].values.split(/\n|,/).length < 30
    },
    copyOriginalEntities() {
      for (const e of this.entities) {
        if (!this.entitiesOriginal[e.name]) {
          this.entitiesOriginal[e.name] = JSON.stringify(e)
        }
      }
    },
  },
  created() {
    // lazy load data
    if (!this.entities.length) {
      this.$auth.apiReady().then(() => {
        this.$store.dispatch('rest/getEntities', {}).then(() => {
          this.copyOriginalEntities()
        })
      })
    }
    this.copyOriginalEntities()
  },
  watch: {
    entities: {
      handler: function (old, n) {
        // console.log("entity modification", old, n);
      },
      deep: true,
    },
  },
}
</script>
