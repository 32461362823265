// import isScreen from '@/core/screenHelper'

import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    atimes: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    updateAtime(state, changes) {
      Object.assign(state.atimes, changes)
    },
  },
  actions: {
    recordAtime({ commit }, changes) {
      commit('updateAtime', changes)
    },
  },
}
