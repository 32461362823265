<template>
  <b-row>
    <div class="col">
      <h2>Users Projects Assignment</h2>
      <hr />
      <b-alert :show="msalRolesSynchronization" variant="info">
        Project roles are synchronized through MSAL. Please manage roles in the MS AAD.
      </b-alert>

      <b-row>
        <b-col cols="2"><label for="filter-by-select" class="mt-1"> Filter by user:</label> </b-col>
        <b-col cols="9">
          <b-form-select v-model="filterByUserId" size="sm" class="" id="filter-by-select">
            <option :value="false">--- select user to filter by ---</option>
            <option v-for="u in usersSorted" :value="u.id">{{ u.name + ' - ' + u.email }}</option>
          </b-form-select>
        </b-col>
        <b-col>
          <b-btn variant="outline-dark" size="sm" @click="filterByUserId = false">X</b-btn>
        </b-col>
      </b-row>

      <hr class="mb-4" />

      <div v-for="(project, projectId) in usersProjects" :key="'v-for-project-' + projectId">
        <div
          v-if="
            getProjectsById &&
            (!filterByUserId || (filterByUserId && filterByUserId && isUserOnProject(projectId, filterByUserId)))
          "
        >
          <div class="d-flex btn p-0" v-b-toggle="'project-' + projectId">
            <span class="flex-grow-1 text-left"> {{ projectId }} - {{ getProjectsById[projectId].name }}</span>
            <i class="fa fa-caret-down my-auto mr-3"></i>
          </div>

          <b-collapse :id="'project-' + projectId" class="mt-2">
            <autocomplete
              :id="'users-autocomplete-' + projectId"
              :search="usersAutocomplete"
              @submit="addUserToProject(projectId, $event)"
              :get-result-value="getUsersAutocompleteValue"
              :autoSelect="true"
              :ref="'users_search' + projectId"
              placeholder="Find and add user"
              class="col-6 m-2"
            ></autocomplete>
            <lazy-component wrapper-tag="div">
              <table class="table table-striped table-sm hover" hover>
                <thead>
                  <tr>
                    <th>User Id</th>
                    <th>Email</th>
                    <th>User Name</th>
                    <th class="text-center"> Assigned role </th>
                    <th class="text-center"> Disable Access </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="projectPermissions in project"
                    :key="projectId + '-permissions-' + projectPermissions.user_id + 'asdf' + projectPermissions.role_id"
                    v-if="!filterByUserId || (filterByUserId && projectPermissions.user_id == filterByUserId)"
                  >
                    <td class="pl-3">
                      {{ projectPermissions.user_id }}
                    </td>
                    <td v-if="usersById[projectPermissions.user_id]">
                      {{ usersById[projectPermissions.user_id].email }}
                    </td>
                    <td v-if="usersById[projectPermissions.user_id]">
                      {{ usersById[projectPermissions.user_id].name }}
                    </td>
                    <td class="text-center">
                      <b-form-select v-model="projectPermissions.role_id" :options="rolesSelect" size="sm"></b-form-select>
                    </td>
                    <td class="text-center">
                      <i
                        class="fa fa-trash-alt mt-2 ml-3 my-auto"
                        @click="removeUserFromProject(projectId, projectPermissions.user_id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </lazy-component>
          </b-collapse>
          <hr class="m-1" />
        </div>
      </div>
    </div>

    <div class="col">
      <div class="d-flex">
        <h2 class="flex-grow-1">Roles</h2>
        <div class="row" v-if="checkPermissions('/system/roles/permissions', 'PUT')">
          <b-form-input
            v-model="newRoleName"
            class="col my-auto mx-2"
            placeholder="Role name"
            v-on:keyup.enter="addRole"
          ></b-form-input>
          <b-button size="" @click="addRole()" variant="primary" class="my-auto"> Add Role </b-button>
        </div>
      </div>
      <hr />

      <div v-for="role in roles" :key="'role-' + role.id">
        <div class="d-flex btn p-0" v-b-toggle="'role-' + role.id">
          <h4 class="flex-grow-1 text-left m-1">{{ role.name }} </h4>
          <i class="fa fa-caret-down my-auto mr-3"></i>
          <i class="fa fa-trash-alt mt-2 ml-3 my-auto" @click="deleteRole(role.id)"></i>
        </div>

        <b-collapse :id="'role-' + role.id" class="mt-2">
          <table class="table table-striped table-sm hover" hover>
            <thead>
              <tr>
                <th>Permission name</th>
                <th class="text-center">
                  Allowed
                  <b-button variant="outline-secondary" size="sm" class="mx-3" @click="selectAll(role.id)">Select All</b-button>
                  <b-button variant="outline-secondary" size="sm" @click="unselectAll(role.id)">Unselect All</b-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="permission in permissionsSorted" :key="role.id + '-role-permission-' + permission.id">
                <td class="pl-3">
                  {{ permissions[permission.id].name }}
                  <span v-if="permissionsOverlapping[permission.id]">
                    <i class="far fa-question-circle" :id="role.id + '-permissions-included-' + permission.id"></i>
                    <b-tooltip :target="role.id + '-permissions-included-' + permission.id" triggers="hover">
                      Permission also includes:
                      <ul>
                        <li
                          v-for="includedPermissionId in permissionsOverlapping[permission.id]"
                          :key="permission.id + 'tooltip' + includedPermissionId"
                        >
                          <b> {{ permissions[includedPermissionId].name }}</b>
                        </li>
                      </ul>
                    </b-tooltip>
                  </span>
                </td>
                <td class="text-center">
                  <b-form-checkbox
                    v-model="roles[role.id].permissions"
                    :value="permission.id"
                    @change="selectOverlappingPermissions(role.id, permission.id)"
                  >
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
        <hr class="m-1" />
      </div>

      <div
        class="float-right"
        style="position: fixed; right: 40px; bottom: 2em"
        v-if="checkPermissions('/system/roles/permissions', 'PUT')"
      >
        <b-btn-group>
          <b-btn id="save-button-secondary" variant="primary" class="box-shadow-2 p-2" @click="saveRolesPermissions()"
            >Save Roles & Permissions</b-btn
          >
          <b-tooltip target="save-button-secondary" triggers="hover">
            <small>Ctrl + S</small>
          </b-tooltip>
        </b-btn-group>

        <GlobalEvents @keydown.ctrl.83.stop.prevent="saveRolesPermissions()" />
      </div>
    </div>
  </b-row>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import LazyComponent from 'v-lazy-component'

export default {
  name: 'Roles',
  components: { LazyComponent },
  computed: {
    ...mapFields('rest', ['roles', 'permissions', 'permissionsResources', 'usersProjects', 'users']),
    ...mapGetters('rest', ['getProjectsById']),
    permissionsSorted() {
      return Object.values(this.permissions).sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    permissionsOverlapping() {
      let overlapping = {}

      for (const [permissionId, permission] of Object.entries(this.permissions)) {
        for (const [permissionId2, permission2] of Object.entries(this.permissions)) {
          if (permission2.project_env == permission.project_env || permission.project_env == null) {
            if (
              permissionId != permissionId2 &&
              permission.resources.every(function (val) {
                return permission2.resources.indexOf(val) >= 0
              })
            ) {
              if (!overlapping[permissionId2]) {
                overlapping[permissionId2] = []
              }
              overlapping[permissionId2].push(parseInt(permissionId))
            }
          }
        }
      }
      return overlapping
    },
    usersById() {
      let users = {}
      for (let u of this.users) {
        users[u.id] = u
      }
      return users
    },
    rolesSelect() {
      let select = []
      for (const id in this.roles) {
        select.push({ value: id, text: this.roles[id].name })
      }
      return select
    },
    usersSorted() {
      return _.sortBy(this.users, [(user) => user.name.toLowerCase(), 'email'])
    },
  },
  data() {
    return {
      newRoleName: '',
      showRoles: {},
      originalRoles: null,
      originalUsersProjects: null,
      msalRolesSynchronization: process.env.VUE_APP_MSAL_ENABLED,
      filterByUserId: false,
    }
  },
  created() {
    this.$auth.apiReady().then(() => {
      this.$store.dispatch('rest/getRoles', {}).then(() => {
        this.originalRoles = JSON.stringify(this.roles)
      })
      this.$store.dispatch('rest/getPermissions', {})
      this.$store.dispatch('rest/getPermissionsResources', {})
      this.$store.dispatch('rest/getUsersProjects', {}).then(() => {
        this.originalUsersProjects = JSON.stringify(this.usersProjects)
      })
    })
  },
  methods: {
    selectAll(roleId) {
      this.roles[roleId].permissions = Object.keys(this.permissions).map((k) => parseInt(k))
    },
    unselectAll(roleId) {
      this.roles[roleId].permissions = []
    },
    addRole() {
      if (!this.newRoleName) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Role name cannot be empty',
        })
        return
      }

      for (const k in this.roles) {
        if (this.roles[k].name == this.newRoleName) {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Duplicit role name',
          })
          return
        }
      }

      this.$store.dispatch('rest/insertRole', { data: { name: this.newRoleName } }).then((response) => {
        let id = response.data.id
        this.$set(this.roles, id, { id: id, permissions: [], name: this.newRoleName })
      })
    },
    deleteRole(roleId) {
      this.$bvModal
        .msgBoxConfirm(
          `Do You want to delete role?
        \n\n
          Deleting this role will cause removing all the users with this role from all the projects where this role was assigned.`,
          {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$store.dispatch('rest/deleteRole', { params: { id: roleId } }).then((response) => {
              this.$delete(this.roles, roleId)
              this.$notify({
                group: 'app',
                type: 'success',
                title: 'Role deleted',
              })
            })
          }
        })
    },
    saveRolesPermissions() {
      let updates = []
      if (this.originalRoles != JSON.stringify(this.roles)) {
        this.originalRoles = JSON.stringify(this.roles)
        updates.push(
          this.$store.dispatch('rest/updateRolesPermissions', { data: this.roles }).then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: 'Roles permissions saved',
            })
          })
        )
      }

      if (this.originalUsersProjects != JSON.stringify(this.usersProjects)) {
        this.originalUsersProjects = JSON.stringify(this.usersProjects)
        updates.push(
          this.$store.dispatch('rest/updateUsersProjects', { data: this.usersProjects }).then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: 'Projects permissions saved',
            })
          })
        )
      }
      if (updates.length == 0) {
        this.$notify({
          group: 'app',
          type: 'info',
          title: 'No changes to save',
        })
      } else {
        Promise.all(updates).then(() => {
          setTimeout(() => {
            window.location.reload() //refresh permissions @TODO there is an easier way
          }, 300)
        })
      }
    },
    usersAutocomplete(query) {
      query = query.toLowerCase()
      return this.users.filter((u) => {
        return u.name.toLowerCase().indexOf(query) !== -1 || u.email.toLowerCase().indexOf(query) !== -1
      })
    },
    getUsersAutocompleteValue(user) {
      return user.name + ', ' + user.email
    },
    addUserToProject(projectId, user) {
      if (!user) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'No user selected',
        })
        return
      }
      if (this.usersProjects[projectId].filter((x) => x.user_id == user.id).length) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'User already added on this project',
        })
        return
      }

      this.usersProjects[projectId].push({ user_id: user.id, role_id: 2 }) // default role is 2
      this.$refs['users_search' + projectId][0].setValue('')
    },
    removeUserFromProject(projectId, userId) {
      this.$set(
        this.usersProjects,
        projectId,
        this.usersProjects[projectId].filter((x) => x.user_id != userId)
      )
    },
    selectOverlappingPermissions(roleId, permissionId) {
      if (this.permissionsOverlapping[permissionId]) {
        this.$nextTick(() => {
          if (this.roles[roleId].permissions.includes(permissionId)) {
            this.permissionsOverlapping[permissionId].forEach((o) => {
              if (!this.roles[roleId].permissions.includes(o)) {
                this.roles[roleId].permissions.push(o)
              }
            })
          }
        })
      }
    },
    isUserOnProject(projectId, userId) {
      return this.usersProjects[projectId].filter((x) => x.user_id == userId).length
    },
  },
}
</script>
<style>
.tooltip-inner {
  max-width: 400px !important;
}
</style>
