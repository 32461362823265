<template>
  <div>
    <div class="card shadow mb-4">
      <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between">
        <div>
          <h6 class="m-0 font-weight-bold text-primary text-inline">Tests</h6>
          <span class="small text-muted">
            <a href="/docs/tests/" target="_blank">
              Docs
              <i class="fas fa-external-link-alt"></i>
            </a>
          </span>
        </div>

        <div>
          <span class="text-success">{{ testsStats.ok }} Ok</span> /
          <span class="text-danger">{{ testsStats.error }} Failed</span> /
          <span class>{{ testsStats.unknown }} Didn't Run</span> /
          <span class>{{ testsStats.pending }} Pending</span>
        </div>

        <div>
          <b-button
            variant="primary"
            size="sm"
            @click="addTest()"
            class="float-right px-2"
            v-if="checkPermissions('/tests/<id:int>', 'PUT')"
          >
            + Add
            <small>(Ctrl+a)</small>
          </b-button>
          <GlobalEvents @keydown.ctrl.65.prevent="addTest()" v-if="currentTestId === false" />

          <b-button variant="warning" size="sm" @click="runAllTests()" class="float-right mx-2">
            Run all
            <small>(Ctrl+Shift+t)</small>
          </b-button>
          <GlobalEvents @keydown.ctrl.shift.prevent.84="runAllTests()" v-if="currentTestId === false" />
          <!-- ctrl + shift + t -->
          <GlobalEvents @keydown.ctrl.shift.84.prevent="runSingleTest(currentTestId)" v-if="currentTestId !== false" />
        </div>
      </div>
    </div>

    <div v-for="(t, j) in tests" :key="'tests' + j">
      <b-card no-body class="my-1">
        <b-card-header class="p-2 d-flex">
          <div class="cols-6 flex-grow-1">
            <span class="text-primary font-weight-bold">
              <h6 class="mb-0">{{ t.name }}</h6>
            </span>
          </div>

          <h5 class="mb-0">
            <i class="fa fa-play float-right" title="Run test" @click.stop="runSingleTest(j)" v-if="t.state != 'pending'"></i>
            <i class="fa fa-cog fa-spin float-right" v-if="t.state == 'pending'" @click.stop="runSingleTest(j)"></i>
            <i
              class="fa fa-circle float-right text-secondary mx-1"
              title="Status"
              :class="{ 'text-success': t.state == 'ok', 'text-danger': t.state == 'error' }"
            ></i>
            <i
              class="fa fa-clone text-default float-right mx-1"
              @click.stop="cloneTest(j)"
              title="Clone test"
              v-if="checkPermissions('/tests/<id:int>', 'PUT')"
            ></i>
            <i
              class="fa fa-pencil-alt text-default float-right mx-1"
              @click="editTest(j)"
              title="Edit test"
              v-if="checkPermissions('/tests/<id:int>', 'PUT')"
            ></i>
          </h5>
        </b-card-header>
        <b-card-text>
          <div class="m-2">
            <b-row>
              <b-col cols="4">User's input</b-col>
              <b-col cols="3">Matched Reactions</b-col>
              <b-col cols="3">Test expresion</b-col>
              <b-col cols="2">Executed Actions</b-col>
            </b-row>
            <hr class="my-1" />

            <div v-for="(step, k) in t.steps" :key="'step-' + k" :class="{ 'bg-gray-100': k % 2 }" class="pt-1">
              <b-row>
                <b-col cols="4">
                  <p class="text-info mb-1">{{ step.input }}</p>
                </b-col>
                <b-col>
                  <span v-for="(r, i) in step.reactions" :key="'step-reaction-' + i" class="text-info">
                    <b-badge variant="primary" size="sm" class="ml-1" v-if="getReactionById(r)">{{
                      getReactionById(r).name
                    }}</b-badge>
                    <b-badge variant="warning" size="sm" class="ml-1" v-if="!getReactionById(r)">Undefined</b-badge>
                  </span>
                </b-col>

                <b-col>
                  <code>{{ step.condition }}</code>
                </b-col>
                <b-col>
                  <span v-for="(a, i) in step.actions" :key="'step-condition-' + i" class="text-info">
                    <b-badge variant="secondary" size="sm" class="ml-1" v-if="getActionById(a)">{{
                      getActionById(a).name
                    }}</b-badge>
                    <b-badge variant="warning" size="sm" class="ml-1" v-if="!getActionById(a)">Undefined</b-badge>
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-show="t.result" :key="'res' + j + t.showOutput">
            <b-tabs card content-class="mt-3" :id="'test-output-' + j">
              <b-tab title="Test Output X" class="p-1">
                <template v-slot:title>
                  <!-- <div  @click="tests[j].showOutput = !t.showOutput"> -->
                  <div @click="toogleTestResult(j)">
                    <i
                      class="fa fa-circle text-secondary"
                      :class="{ 'text-success': t.state == 'ok', 'text-danger': t.state == 'error' }"
                    ></i>
                    Output
                    <i class="fa" :class="{ 'fa-caret-up': t.showOutput, 'fa-caret-down': !t.showOutput }"></i>
                  </div>
                </template>
                <div class="bg-gray-100 pt-0 px-2 rounded box-shadow-2 mt-0" v-if="t.showOutput" :key="'show' + j">
                  <!-- {{t.result}} -->
                  <div v-if="t.result.tracker != undefined">
                    <Conversation :tracker="t.result.tracker" :level="logLevel" :entitiesLoader="fetchEntityByName" />
                  </div>

                  <!-- <span v-for="(l, i) in t.result.log" :key="'outputline'+i">
                    <span :class="consoleOutputClassMap[l.type]">{{l.text}}</span>
                    <br />
                  </span>-->
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-card-text>
      </b-card>
    </div>

    <div class="fixed-bottom m-3 mb-2">
      <div class="input-group col-2 float-right">
        <div class="input-group-prepend">
          <label class="input-group-text box-shadow-3" for="inputGroupSelect01">Log level</label>
        </div>
        <b-form-select
          v-model="logLevel"
          :options="availableLogLevels"
          class="w-auto float-right py-0 box-shadow-3"
        ></b-form-select>
      </div>
    </div>

    <b-modal
      ref="my-modal"
      id="modal-2"
      v-bind:title="'Edit ' + (currentTestId !== false ? tests[currentTestId].name : '')"
      size="xl"
      @ok="saveFunction"
      @cancel="forceCloseDialogue"
      @hide.prevent="closeDialogue"
      :key="'current-test-id-' + currentTestId"
    >
      <b-container fluid v-if="currentTestId !== false">
        <!-- <GlobalEvents @keypress.ctrl.shift.enter="saveAndRunFunction" /> -->
        <GlobalEvents @keypress.ctrl.enter="saveFunction" />
        <b-row>
          <b-col class="col-12 px-0">
            <div class="row">
              <div class="input-group mb-3 col-9">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">Test identifier</span>
                </div>
                <input v-model="tests[currentTestId].name" type="text" class="form-control" id="basic-url" />
              </div>
              <div @click="removeTest()" class="mx-4 mt-1 py-1">
                Remove test case
                <i class="fa fa-trash-alt fa-lg"></i>
              </div>
            </div>

            <!-- <hr /> -->

            <h5 class="text-primary">Test Script</h5>

            <div>
              <div class>
                <table class="table table-lg mt-lg mb-0">
                  <thead>
                    <tr class="d-flex">
                      <th class="col-4">User's input</th>
                      <th class="col-3">Matched Reactions</th>
                      <th class="col-3">Test expresion</th>
                      <th class="col-2">Executed actions</th>
                      <!-- <th class="col-1">Delete</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <Container
                      group-name="1"
                      :get-child-payload="getStepFromListPayload"
                      @drop="onStepDrop(tests[currentTestId].steps, $event)"
                      orientation="vertical"
                      lock-axis="y"
                      drag-handle-selector=".testGrab"
                      non-drag-area-selector=".testGrab *"
                    >
                      <Draggable
                        v-for="(step, i) in tests[currentTestId].steps"
                        :key="'current-step-' + i"
                        style="overflow: visible"
                      >
                        <b-row class="py-2">
                          <b-col cols="0" class="d-flex flex-column justify-content-center fa fa-grip-vertical testGrab">
                          </b-col>
                          <b-col cols="4" class="testGrab">
                            <b-textarea v-model="tests[currentTestId].steps[i].input" :ref="'utter-input-' + i" />
                          </b-col>
                          <b-col cols="3">
                            <!-- <Multiselect
                            v-model="tests[currentTestId].steps[i].conditions"
                            tag-placeholder="Add this expression"
                            placeholder="Search Reactions"
                            :options="Object.keys(reactionsMap)"
                            :multiple="true"
                            :taggable="false"
                            @tag="addTag(i, $event)"
                            class
                          ></Multiselect>-->

                            <div style="min-height: 1em">
                              <Container
                                :group-name="'reactions-' + step.id"
                                drag-handle-selector=".reactionGrab"
                                non-drag-area-selector="i.fa.fa-times"
                                @drop="onReactionDrop(step, $event)"
                              >
                                <Draggable
                                  v-for="(r, j) in step.reactions"
                                  :key="'current-reaction-id' + r.id"
                                  class="col pl-0 pr-1"
                                >
                                  <b-row>
                                    <b-badge variant="primary" class="mb-1 ml-3 reactionGrab" v-if="getReactionById(r)"
                                      ><small><i class="fa fa-grip-vertical"></i></small>
                                      {{ getReactionById(r).name }}
                                      <i class="fa fa-times ml-1" @click="removeStepReaction(i, j)"></i>
                                    </b-badge>
                                    <b-badge variant="warning" class="mb-1 ml-3" v-if="!getReactionById(r)">
                                      Undefined!
                                      <i class="fa fa-times ml-1" @click="removeStepReaction(i, j)"></i>
                                    </b-badge>
                                  </b-row>
                                </Draggable>
                              </Container>
                            </div>

                            <autocomplete
                              :search="reactionsAutocomplete"
                              @submit="appendReactionsFromAutocomplete(i, $event)"
                              :autoSelect="true"
                              :ref="'reactions_search-' + i"
                              placeholder="Find reaction"
                              class="my-auto"
                              :key="'autocomplete-reactions-' + tests[currentTestId].steps[i].reactions.length"
                            ></autocomplete>
                          </b-col>
                          <b-col class="testGrab">
                            <div style="min-height: 1em"></div>
                            <b-input
                              placeholder="expression to check"
                              size="sm"
                              v-model="tests[currentTestId].steps[i].condition"
                            />
                          </b-col>
                          <b-col cols="2" class="testGrab">
                            <div style="min-height: 1em">
                              <b-row
                                v-for="(a, j) in step.actions"
                                :key="'current-test-step-actions-' + j"
                                class="col pl-0 pr-1"
                              >
                                <b-badge variant="secondary" class="mb-1 ml-3" v-if="getActionById(a)">
                                  {{ getActionById(a).name }}
                                  <i class="fa fa-times ml-1" @click="removeStepAction(i, j)"></i>
                                </b-badge>
                                <b-badge variant="warning" class="mb-1 ml-3" v-if="!getActionById(a)">
                                  Undefined!
                                  <i class="fa fa-times ml-1" @click="removeStepAction(i, j)"></i>
                                </b-badge>
                              </b-row>
                            </div>

                            <!-- <b-row class="py-1"> -->
                            <!-- <b-input size="sm" class="col-10" />
                              <b-button
                                size="sm"
                                variant="primary"
                                class="ml-1 box-shadow-2"
                                @click="tests[currentTestId].steps[i].conditions.push(this.value)"
                              >
                                <i class="fa fa-plus fa-xs" />
                          </b-button>-->

                            <autocomplete
                              :search="actionsAutocomplete"
                              @submit="appendActionsFromAutocomplete(i, $event)"
                              :autoSelect="true"
                              :ref="'actions_search-' + i"
                              placeholder="Find action"
                              class="colss-11"
                              :key="'autocomplete-actions-' + tests[currentTestId].steps[i].actions.length"
                            ></autocomplete>
                            <!-- </b-row> -->
                          </b-col>
                          <b-col cols="0" class="testGrab">
                            <div @click="removeTestStep(i)" class="col fa fa-trash-alt float-right mr-0 pl-2 mt-4"></div>
                          </b-col>
                        </b-row>
                        <hr class="m-0" />
                      </Draggable>
                    </Container>
                  </tbody>
                </table>
                <div class="mt-5 ml-2">
                  <b-input-group prepend="Add Next Step" class="mt-3 col-8">
                    <b-input
                      placeholder="User's utterance (Ctrl+D)"
                      v-model="nextStepUtter"
                      @keyup.enter.native="addStep"
                      :state="nextStepUtterInputState"
                      ref="add-new-utterance-input"
                    ></b-input>
                    <GlobalEvents
                      @keydown.ctrl.68.prevent="$refs['add-new-utterance-input'].$el.focus()"
                      v-if="currentTestId !== false"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="addStep">Add</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Editable from '@/components/Editable/Editable'
import { Container, Draggable } from 'vue-smooth-dnd'
import Multiselect from 'vue-multiselect'
import Conversation from '@/components/Conversation/Conversation'

export default {
  name: 'Tests',
  components: {
    editable: Editable,
    Container,
    Draggable,
    Multiselect,
    Conversation,
  },
  computed: {
    ...mapFields('rest', ['tests', 'reactions', 'actions', 'runAllTestsTrigger', 'availableLogLevels']),
    reactionsMap() {
      var m = {}
      this.reactions.map((r) => (m[r.name] = r))
      return m
    },
    actionsMap() {
      var m = {}
      this.actions.map((a) => (m[a.name] = a))
      return m
    },
    testsStats() {
      var stats = { ok: 0, error: 0, unknown: 0, pending: 0 }
      for (var t of this.tests) {
        if (t.state) {
          stats[t.state]++
        } else {
          stats['unknown']++
        }
      }
      return stats
    },
  },
  data() {
    return {
      currentTestId: false,
      nextStepUtter: '',
      nextStepUtterInputState: undefined,
      editTestState: {},
      isNewTest: false,
      consoleOutputClassMap: {
        bot: 'text-warning',
        user: 'text-info',
        error: 'text-danger font-weight-bold',
        ok: 'text-success',
        warn: 'text-primary',
      },
      pendingCnt: 0,
      allTestsWereRun: false,
      lastRunTestIndex: 0,
      logLevel: 'fine',
    }
  },
  methods: {
    getActionById(id) {
      for (var a of this.actions) {
        if (a.id == id) {
          return a
        }
      }
    },
    getReactionById(id) {
      for (var r of this.reactions) {
        if (r.id == id) {
          return r
        }
      }
    },
    generalAutocomplete(array, query) {
      var q = query.toLowerCase()

      return array
        .filter((a) => {
          return a.name.toLowerCase().startsWith(q)
        })
        .map((x) => x.name)
    },
    actionsAutocomplete(query) {
      return this.generalAutocomplete(this.actions, query)
    },
    reactionsAutocomplete(query) {
      return this.generalAutocomplete(this.reactions, query)
    },
    appendActionsFromAutocomplete(step, name) {
      var id = this.actionsMap[name].id
      if (id === undefined) {
        return
      }
      this.tests[this.currentTestId].steps[step].actions.push(id)
      this.$refs['actions_search-' + step][0].value = ''
    },
    appendReactionsFromAutocomplete(step, name) {
      var id = this.reactionsMap[name].id
      if (id === undefined) {
        return
      }
      this.tests[this.currentTestId].steps[step].reactions.push(id)
      // this.$set(this.tests[this.currentTestId].steps[step], 'reactions', this.tests[this.currentTestId].steps[step].reactions) // force rerender
      // this.$set(this.tests[this.currentTestId], 'steps', this.tests[this.currentTestId].steps) // force rerender
      this.$refs['reactions_search-' + step][0].value = ''
      this.$forceUpdate() //f**king Vue (array push sould trigger reactivity, but sometimes it do not work properly)
    },
    // addTag(step, tag) {
    //   this.tests[this.currentTestId].steps[step].conditions.push(tag)
    // },
    addStep() {
      if (!this.nextStepUtter) {
        this.nextStepUtterInputState = false
        return
      }

      this.nextStepUtterInputState = undefined
      var index = this.tests[this.currentTestId].steps.length
      this.tests[this.currentTestId].steps.push({
        input: this.nextStepUtter,
        condition: '',
        reactions: [],
        actions: [],
      })
      this.$set(this.tests[this.currentTestId], 'steps', this.tests[this.currentTestId].steps) //force rerender
      this.nextStepUtter = ''

      // nextTick does not work
      var trySetFocusWithTimeout = () => {
        if (!this.$refs['utter-input-' + index]) {
          setTimeout(() => {
            trySetFocusWithTimeout()
          }, 25)
        } else {
          console.log('input refs:', this.$refs['utter-input-' + index])
          this.$refs['utter-input-' + index][0].$el.focus()
        }
      }

      trySetFocusWithTimeout()
    },
    addTest() {
      var baseName = 'new_test'
      var newName = baseName
      for (var i = 1; i < 999; i++) {
        newName = baseName + '_' + i
        if (!this.tests.map((x) => x.name).filter((x) => x === newName).length) {
          break
        }
      }

      var test = {
        name: newName,
        steps: [
          {
            input: 'Users is asking this',
            reactions: [],
            condition: '',
            actions: [],
          },
        ],
      }

      this.isNewTest = true
      this.tests.push(test)
      this.editTestState = JSON.parse(JSON.stringify(test))

      this.currentTestId = this.tests.length - 1
      this.$nextTick(() => {
        this.$refs['my-modal'].show()
      })
    },
    removeTest() {
      var title = 'Do You want to permanently delete test scenario ' + this.tests[this.currentTestId].name + ' ?'

      this.$bvModal
        .msgBoxConfirm(title, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$store
              .dispatch('rest/deleteTest', {
                params: { id: this.tests[this.currentTestId].id },
              })
              .finally(() => {
                this.$delete(this.tests, this.currentTestId)
                this.currentTestId = false
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    removeTestStep(i) {
      this.tests[this.currentTestId].steps.splice(i, 1)
      this.$set(this.tests, this.currentTestId, this.tests[this.currentTestId])
    },
    saveFunction() {
      if (this.tests.filter((x) => x.name == this.tests[this.currentTestId].name).length != 1) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Duplicit test name, please change the test name!',
        })
        return false
      }

      let runTestIndex = this.currentTestId
      if (this.isNewTest) {
        this.$store.dispatch('rest/insertTest', { data: this.tests[this.currentTestId] }).then((res) => {
          this.tests[this.currentTestId].id = res.data.id
          this.currentTestId = false

          this.runSingleTest(runTestIndex)
          return this.$notify({
            group: 'app',
            type: 'success',
            title: 'Test created',
          })
        })

        return
      }

      if (!this.isNewTest || JSON.stringify(this.editTestState) != JSON.stringify(this.tests[this.currentTestId])) {
        this.$store
          .dispatch('rest/updateTest', {
            params: { id: this.tests[this.currentTestId].id },
            data: this.tests[this.currentTestId],
          })
          .then(() => {
            this.runSingleTest(runTestIndex)
            return this.$notify({
              group: 'app',
              type: 'success',
              title: 'Test updated',
            })
          })
      }
      this.currentTestId = false
    },
    closeDialogue(event) {
      if (event.trigger == 'ok' || event.trigger == 'cancel') {
        return
      }

      if (!this.isNewTest && JSON.stringify(this.editTestState) == JSON.stringify(this.tests[this.currentTestId])) {
        this.forceCloseDialogue()
        return
      }

      this.$bvModal
        .msgBoxConfirm('Do You want to discard changes?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.forceCloseDialogue()
          } else {
            event.preventDefault()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onStepDrop(arr, dropResult) {
      const { removedIndex, addedIndex, payload } = dropResult
      if (removedIndex === null && addedIndex === null) {
        return
      }

      var itemToAdd = JSON.parse(JSON.stringify(payload))
      itemToAdd.args = []

      if (removedIndex !== null) {
        arr.splice(removedIndex, 1)
      }

      if (addedIndex !== null) {
        // deep copy of payload
        var o = JSON.parse(JSON.stringify(payload))
        if (!o.args) {
          o.args = []
        }
        arr.splice(addedIndex, 0, o)
      }
    },
    /**
     * @param step
     * @param {{removedIndex,addedIndex,payload}} dropResult
     */
    onReactionDrop(step, dropResult) {
      if (dropResult.removedIndex === null || dropResult.addedIndex === null) {
        return
      }
      let movedReaction = step.reactions[dropResult.removedIndex]
      step.reactions.splice(dropResult.removedIndex, 1)
      step.reactions.splice(dropResult.addedIndex, 0, movedReaction)
    },
    getStepFromListPayload(index) {
      return this.tests[this.currentTestId].steps[index]
    },
    editTest(i) {
      this.currentTestId = i
      this.isNewTest = false

      this.editTestState = JSON.parse(JSON.stringify(this.tests[i]))
      this.$nextTick(() => {
        this.$refs['my-modal'].show()
      })
    },
    forceCloseDialogue() {
      if (this.isNewTest) {
        this.currentTestId = false
        this.tests.pop()
        return
      }

      if (this.currentTestId !== false) {
        this.tests[this.currentTestId] = JSON.parse(JSON.stringify(this.editTestState))
        this.currentTestId = false
      }
    },
    removeStepReaction(step, reaction) {
      // this.tests[this.currentTestId].steps[step].reactions.splice(reaction, 1);
      this.$delete(this.tests[this.currentTestId].steps[step].reactions, reaction)
      this.$set(this.tests[this.currentTestId].steps[step], 'reactions', this.tests[this.currentTestId].steps[step].reactions)
    },
    removeStepAction(step, action) {
      // this.tests[this.currentTestId].steps[step].actions.splice(action, 1);
      this.$delete(this.tests[this.currentTestId].steps[step].actions, action)
    },
    cloneTest(index) {
      var copy = JSON.parse(JSON.stringify(this.tests[index]))
      copy.id = undefined
      copy.state = 'unknown'
      var baseName = copy.name + '_copy'
      var newName = baseName

      for (var i = 1; i < 999; i++) {
        newName = baseName + '_' + i
        if (!this.tests.map((x) => x.name).filter((x) => x === newName).length) {
          break
        }
      }
      copy.name = newName

      this.tests.push(copy)
      this.editTestState = JSON.parse(JSON.stringify(copy))
      this.isNewTest = true

      this.currentTestId = this.tests.length - 1
      this.$nextTick(() => {
        this.$nextTick(() => {
          // one nextTick does not work
          this.$refs['my-modal'].show()
        })
      })
    },
    runSingleTest(index) {
      this.allTestsWereRun = false
      this.runTest(index)
    },
    runTest(index) {
      var id = this.tests[index].id
      this.$set(this.tests[index], 'state', 'pending')
      this.$set(this.tests, index, this.tests[index]) // force rerender
      this.pendingCnt++
      this.$nextTick(() => {
        this.$store
          .dispatch('rest/runTest', {
            params: { id: id },
          })
          .then((x) => {
            this.$nextTick(() => {
              this.$set(this.tests[index], 'result', x.data)
              this.$set(this.tests[index], 'state', x.data.result)

              if (x.data.result != 'ok') {
                this.tests[index].showOutput = true
              }
            })
          })
          .finally(() => {
            this.currentTestId = false
            this.pendingCnt--
            this.lastRunTestIndex = index
          })
      })
    },
    runAllTests() {
      for (var i = 0; i < this.tests.length; i++) {
        this.runTest(i)
      }
      this.allTestsWereRun = true
    },
    toogleTestResult(index) {
      this.tests[index].showOutput = !this.tests[index].showOutput

      setTimeout(() => {
        // for some reason, $nextTick() is not working but setTimeout 0 does
        this.$set(this.tests, index, this.tests[index]) //force reactivity
        if (this.tests[index].showOutput) {
          this.$nextTick().then(() => {
            document.querySelector('#test-output-' + index).scrollIntoView()
          })
        }
      }, 0)
    },
    fetchEntityByName() {
      return Promise.resolve([])
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    pendingCnt: function (newVal, oldVal) {
      this.$nextTick(() => {
        var state = this.tests[this.lastRunTestIndex].state

        if (newVal == 0) {
          if (this.allTestsWereRun) {
            if (this.testsStats.error == 0 && this.testsStats.unknown == 0 && newVal == 0) {
              return this.$notify({
                group: 'app',
                type: 'success',
                title: 'Tests complete!',
                text: 'All tests successfully completed',
              })
            } else {
              return this.$notify({
                group: 'app',
                type: 'error',
                title: 'Tests unsuccessfully complete!',
                text: this.testsStats.error + ' failed tests (' + this.testsStats.ok + ' ok)',
              })
            }
          } else {
            if (state == 'ok') {
              return this.$notify({
                group: 'app',
                type: 'success',
                title: "Test '" + this.tests[this.lastRunTestIndex].name + "' successfully completed",
              })
            } else if (state == 'error') {
              return this.$notify({
                group: 'app',
                type: 'error',
                title: "Test '" + this.tests[this.lastRunTestIndex].name + "' failed!",
              })
            }
          }

          this.allTestsWereRun = false
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    runAllTestsTrigger: function (newVal, oldVal) {
      if (newVal) {
        this.runAllTests()
        this.runAllTestsTrigger = false
      }
    },
  },
  created: function () {
    this.$events.$on('Tests:editTest', (index) => {
      this.$nextTick(() => {
        this.editTest(index)
      })
    })
  },
}
</script>

<style src="./Tests.scss" lang="scss" />
