<template>
  <div>
    <b-form @submit.prevent="loadPage" class="d-flex mb-1">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text> Webpage URL: </b-input-group-text>
        </b-input-group-prepend>
        <b-input type="text" v-model="url" />
      </b-input-group>
      <b-button class="btn btn-primary" @click="loadPage()">Show</b-button>
    </b-form>
    <div class="alert alert-danger" v-for="error of errors">
      {{ error }}
    </div>
    <div class="pagesContainter">
      <loading
        :active="loading"
        :is-full-page="false"
        background-color="#343a40"
        color="#f24516"
        :z-index="100000"
        :opacity="0.2"
        loader="dots"
        :width="80"
        :can-cancel="true"
      ></loading>

      <img id="previewPage" ref="previewPage" />

      <iframe id="testPage" ref="testPage"> </iframe>
    </div>
    <small>Please note that the page in background server only as example of look.</small>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Loading from 'vue-loading-overlay'
import Conversation from '@/components/Conversation/Conversation'
import ConversationToTest from '@/components/Conversation/ConversationToTest'
import './ChatbotTest.scss'
import getChatbotPath from '@/core/chatbotPathCreator'

export default {
  name: 'ChatbotTest',
  components: { Loading },
  computed: {
    ...mapFields('rest', ['settings']),
  },
  data() {
    return {
      url: 'https://happy-robots.cz',
      errors: [],
      loading: false,
    }
  },
  methods: {
    loadPage() {
      if (!/^https?:\/\//i.test(this.url)) {
        this.url = 'http://' + this.url
      }
      let req = 'https://secure.screeenly.com/api/v1/fullsize'

      this.loading = true

      var data = new FormData()
      data.append('key', 'YRx2RG0tteNwa1yNLcgDxGZbdOQPF1iiSz6HKzX8avC7pOg4nI')
      data.append('url', this.url)
      data.append('width', this.$refs['previewPage'].offsetWidth)
      data.append('height', this.$refs['previewPage'].offsetHeight)

      const xhr = new XMLHttpRequest()
      xhr.open('POST', req)
      xhr.send(data)
      xhr.responseType = 'json'

      xhr.onload = () => {
        this.loading = false
        if (xhr.readyState == 4 && xhr.status == 200) {
          this.$refs['previewPage'].src = 'data:image/jpg;base64,' + xhr.response['base64_raw']
        } else {
          console.log(`Error: ${xhr.status}`)
        }
      }
    },
    loadChatbot() {
      this.errors = []
      let iframeDocument = this.$refs['testPage'].contentWindow.document
      let scriptElement = iframeDocument.createElement('script')
      scriptElement.src = getChatbotPath(this.settings.chatbot_base_url, this.settings.id, 'dev', this.settings.chatbot_token)
      scriptElement.onerror = (e) => {
        this.errors.push(
          'Chatbot bundle loading have failed. Please make sure that the settings were properly saved and deployed.'
        )
      }
      iframeDocument.querySelector('body').appendChild(scriptElement)
    },
  },
  mounted() {
    // this.loadPage()
    if (this.settings.chatbot_base_url) {
      this.loadChatbot()
    }
  },
  watch: {
    settings: function () {
      if (this.settings.chatbot_base_url) {
        this.loadChatbot()
      }
    },
  },
}
</script>
