<template>
  <div>
    <div class="card shadow mb-4">
      <!-- Card Header -->
      <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between">
        <!-- <b-row> -->
        <b-col>
          <h6 class="m-0 font-weight-bold text-primary">Outbound Campaigns</h6>
        </b-col>

        <b-col>
          <b-button
            size="sm"
            variant="primary"
            @click="$refs['showAddCampaignModal'].show()"
            v-if="checkPermissions('/outbound/campaigns/<id:int>', 'PUT')"
            class="float-right"
            >Add Campaign</b-button
          >
        </b-col>
        <!-- </b-row> -->
      </div>
      <!-- Card Body -->
      <div class="card-body">
        <b-modal
          @cancel="$refs['showAddCampaignModal'].hide()"
          @hide="$refs['showAddCampaignModal'].hide()"
          @ok="confirmCampaignChange"
          id="showAddCampaignModal"
          ref="showAddCampaignModal"
          size="lg"
          title="New Outbound Campaign"
        >
          <GlobalEvents @keypress.ctrl.enter="confirmCampaignChange" />

          <b-container fluid>
            <b-row>
              <b-col sm="3">
                <label for="form-name">Name</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-name" type="text" v-model="newCampaign.name"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-env">Environment</label>
              </b-col>
              <b-col sm="9">
                <b-form-select id="form-env" v-model="newCampaign.project_env" :options="envOptions"></b-form-select>
              </b-col>

              <b-col sm="3">
                <label for="form-call-time-from">Call time from (UTC)</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-call-time-from" type="time" v-model="newCampaign.call_time_from"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-call-time-to">Call time to (UTC)</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-call-time-to" type="time" v-model="newCampaign.call_time_to"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-retry-interval">Retry in (minutes)</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-retry-interval" type="number" v-model="newCampaign.retry_interval"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-retry-count">Retry count</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-retry-count" type="number" v-model="newCampaign.retry_count"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-timeout">Timeout (seconds)</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-timeout" type="number" v-model="newCampaign.timeout"></b-form-input>
              </b-col>

              <b-col sm="3">
                <label for="form-callback-url">Callback URL</label>
              </b-col>
              <b-col sm="9">
                <b-form-input id="form-callback-url" type="url" v-model="newCampaign.callback_url"></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>

        <table class="table">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Environment</th>
            <th>Call time from (UTC)</th>
            <th>Call time to (UTC)</th>
            <th>Retry interval</th>
            <th>Retry count</th>
            <th>Timeout</th>
            <th>Callback URL</th>
            <th>Actions</th>
            <th>Pending</th>
            <th>Modify</th>
          </tr>
          <tr v-for="campaign in outboundCampaigns" :key="campaign.id">
            <td> {{ campaign.id }} </td>
            <td> {{ campaign.name }} </td>
            <td> {{ campaign.project_env }} </td>
            <td> {{ campaign.call_time_from }} </td>
            <td> {{ campaign.call_time_to }} </td>
            <td> {{ campaign.retry_interval }} </td>
            <td> {{ campaign.retry_count }} </td>
            <td> {{ campaign.timeout }} </td>
            <td> {{ campaign.callback_url }} </td>
            <td>
              <b-button
                size="sm"
                class="mx-1"
                @click="
                  $refs['upload-file'].show()
                  campaignUploadId = campaign.id
                "
                v-if="checkPermissions('/outbound/campaigns/<id:int>/upload', 'POST')"
                >Import call list</b-button
              >
              <b-button size="sm" variant="primary" class="mx-1" @click="downloadReport(campaign.id)">Download report</b-button>
            </td>
            <td> {{ campaign.pending }} </td>
            <td>
              <i
                class="fa fa-pencil-alt ml-0"
                @click="editCampaign(campaign.id)"
                v-if="checkPermissions('/outbound/campaigns/<id:int>', 'PUT')"
              ></i>
              <i
                class="fa fa-trash-alt ml-3"
                @click="deleteCampaign(campaign.id)"
                v-if="checkPermissions('/outbound/campaigns/<id:int>', 'DELETE')"
              ></i>
            </td>
          </tr>
        </table>

        <b-modal ref="upload-file" size="lg">
          Please select CSV file with two columns:<br />
          <code>destination, scheduled_time</code> <br />
          <br />
          <h5>For example</h5>
          <code>
            destination, scheduled_time<br />
            +420123123123, 2039-01-01 12:43 UTC<br />
            +420123123124, 2039-01-01 12:45 UTC<br />
            +420123123125, 2039-01-01 12:46 UTC<br />
          </code>
          <br />

          <b-form-file
            ref="file"
            placeholder="Choose a CSV file or drop it here..."
            drop-placeholder="Drop file here..."
            @change="handleFileUpload"
          ></b-form-file>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'

export default {
  name: 'Outbound',
  computed: {
    ...mapFields('rest', ['outboundCampaigns']),
  },
  data() {
    return {
      envOptions: [
        { text: 'Production', value: 'prod' },
        { text: 'Developement', value: 'dev' },
      ],
      newCampaign: {
        name: null,
        project_env: 'dev',
        call_time_from: '09:00',
        call_time_to: '17:00',
        retry_interval: 120,
        retry_count: 3,
        timeout: 30,
        callback_url: null,
      },
      campaignUploadId: null,
    }
  },
  methods: {
    confirmCampaignChange(evt) {
      if (!this.newCampaign.name) {
        this.$notify({
          group: 'app',
          type: 'error',
          title: 'Name cannot be empty.',
        })
        evt.preventDefault()
        return
      }

      if (this.newCampaign.id) {
        this.$store
          .dispatch('rest/updateOutboundCampaign', { params: { id: this.newCampaign.id }, data: this.newCampaign })
          .then(() => {
            this.$store.dispatch('rest/getOutboundCampaigns')
            this.$notify({
              group: 'app',
              type: 'success',
              title: 'Campaign updated',
            })
            delete this.newCampaign.id
          })
      } else {
        this.$store.dispatch('rest/insertOutboundCampaign', { data: this.newCampaign }).then(() => {
          this.$store.dispatch('rest/getOutboundCampaigns')
          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Campaign created',
          })
        })
      }

      this.$refs['showAddCampaignModal'].hide()
    },
    deleteCampaign(campaignId) {
      let c = this.outboundCampaigns.find((x) => x.id === campaignId)

      this.$bvModal
        .msgBoxConfirm('Do You really want to delete campaign?', {
          title: 'Delete ' + c.name,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$store
              .dispatch('rest/deleteOutboundCampaign', {
                params: { id: campaignId },
              })
              .then(() => {
                this.$store.dispatch('rest/getOutboundCampaigns')
                this.$notify({
                  group: 'app',
                  type: 'success',
                  title: 'Campaign deleted',
                })
              })
          }
        })
    },
    editCampaign(campaignId) {
      this.newCampaign = JSON.parse(JSON.stringify(this.outboundCampaigns.find((x) => x.id === campaignId)))

      this.newCampaign.call_time_from = this.newCampaign.call_time_from.replace('+00:00', '')
      this.newCampaign.call_time_to = this.newCampaign.call_time_to.replace('+00:00', '')
      this.newCampaign.retry_interval = moment.duration(this.newCampaign.retry_interval).asMinutes()

      this.$refs['showAddCampaignModal'].show()
    },
    downloadReport(campaignId) {
      this.$store
        .dispatch('rest/exportOutboundCampaignReport', {
          params: { id: campaignId },
        })
        .then((response) => {
          let url = window.URL.createObjectURL(response.data)
          let date = new Date().toISOString().slice(0, 10)
          let link = document.createElement('a')
          link.href = url
          link.download = 'OutboundCampaignReport_' + campaignId + '_' + date + '.csv'
          link.click()
        })
        .catch((e) => {
          console.log(e)
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot download file!',
          })
        })
    },
    handleFileUpload(e) {
      let formData = new FormData()
      let filenames = []
      for (var f of e.target.files) {
        filenames.push(f.name)
        formData.append('file', f, f.name)
      }

      this.$bvModal
        .msgBoxConfirm('Do You really want to upload files?', {
          title: 'Upload ' + filenames.join(', '),
          size: 'lg',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.uploading = true

            this.axios
              .post('v1/outbound/campaigns/' + this.campaignUploadId + '/upload', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(() => {
                this.$notify({
                  group: 'app',
                  type: 'success',
                  title: 'Successfully uploaded',
                })
              })
              .catch(() => {
                this.$notify({
                  group: 'app',
                  type: 'error',
                  title: 'Error!',
                  text: 'Cannot upload files!',
                })
              })
            this.$refs['upload-file'].hide()
          } else {
            this.$refs.file.reset()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
