import Vue from 'vue'

import moment from 'moment'

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter('round', function (value, decimals) {
  if (!value) {
    value = 0
  }
  if (!decimals) {
    decimals = 0
  }
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value
})

Vue.filter('timeInHours', function (seconds) {
  var hours = Math.floor(seconds / 3600)
  var minutes = Math.floor((seconds - hours * 3600) / 60)
  seconds = seconds - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ' h ' + minutes + ' m ' + Math.round(seconds) + ' s'
})

Vue.filter('timeInMinutes', function (value) {
  if (!value) {
    return '00:00'
  }
  const sec = parseInt(value, 10) // convert value to number if it's string
  let minutes = Math.floor(sec / 60) // get minutes
  let seconds = sec - minutes * 60 //  get seconds
  // add 0 if value < 10
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return minutes + ':' + seconds // Return is  MM:SS
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment.unix(value).format('YYYY-MM-DD HH:mm')
  }
})

// function formatBytes(bytes, decimals = 2) {
Vue.filter('formatBytes', function (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

Vue.filter('highlightMenuShortcut', function (words, enabled, query) {
  if (enabled) {
    return words.replace(query, '<span class="highlight-menu-shortcut">' + query + '</span>')
  } else {
    return words
  }
})

export default {}
