<template>
  <!-- Topbar -->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow my-auto">
        <b-button-group size="sm">
          <b-button
            variant="warning"
            class="box-shadow-3"
            @click="runTests()"
            id="run-tests-btn"
            v-if="checkPermissions('/tests', 'GET')"
            >Run Tests</b-button
          >
          <b-tooltip target="run-tests-btn" triggers="hover" v-if="checkPermissions('/tests', 'GET')">
            Run all: <small>Ctrl + Shift + t</small>
          </b-tooltip>
          <!-- <GlobalEvents
  @keydown.shift.t.stop.prevent="runTests()"
          />-->
          <b-button variant="info" class="box-shadow-3" @click="runChat()" id="run-chat-btn">Run Chat</b-button>
          <b-tooltip target="run-chat-btn" triggers="hover">
            <small>Ctrl + Shift + X</small>
          </b-tooltip>
          <GlobalEvents @keydown.shift.ctrl.prevent.88="runChat()" />
          <!-- shift + ctrl + X -->

          <b-dropdown
            id="dropdown-right"
            html="Deploy ▾"
            variant="primary"
            size="sm"
            class="box-shadow-3"
            split-variant="outline-primary"
            v-if="checkPermissions('/run/deploy', 'GET')"
          >
            <b-dropdown-item href="#" @click="runDeploy('dev')" v-if="checkPermissions('/run/deploy', 'GET', 'dev')">
              Testing
              <small>(Ctrl + Shift + E)</small>
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="runDeploy('prod')" v-if="checkPermissions('/run/deploy', 'GET', 'prod')"
              >Production</b-dropdown-item
            >
          </b-dropdown>
          <GlobalEvents @keydown.ctrl.shift.e.prevent="runDeploy()" />
        </b-button-group>
      </li>
      <div class="topbar-divider d-none d-sm-block"></div>
      <li class="nav-item">
        <div>
          <b-dropdown id="user-dropdown" variant="outline default">
            <template v-slot:button-content>
              <span>{{ $auth.user().name }}</span>
              <br />
              <span class="small p-0">{{ roleName }}</span>
            </template>
            <b-dropdown-item to="/app/platform-settings"> <i class="fa fa-key"></i> Platform access </b-dropdown-item>
            <b-dropdown-item to="/app/users-logs" v-if="checkPermissions('/system/users-logs', 'GET')">
              <i class="fa fa-address-book"></i> Login logs
            </b-dropdown-item>
            <b-dropdown-item to="/app/roles" v-if="checkPermissions('/system/roles/<id:int>', 'PUT')">
              <i class="fa fa-users"></i> Manage roles
            </b-dropdown-item>
            <b-dropdown-item @click="logoutFn"> <i class="fa fa-sign-out-alt"></i> Logout </b-dropdown-item>
          </b-dropdown>
        </div>
      </li>
    </ul>
    <DeploymentModal ref="deploymentModal"></DeploymentModal>
  </nav>
  <!-- End of Topbar -->
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { mapFields } from 'vuex-map-fields'
import DeploymentModal from '@/components/DeploymentModal/DeploymentModal'

export default {
  name: 'Header',
  components: { DeploymentModal },
  computed: {
    ...mapState('layout', {
      sidebarClose: (state) => state.sidebarClose,
      sidebarStatic: (state) => state.sidebarStatic,
    }),
    ...mapFields('rest', ['chatOpened', 'runAllTestsTrigger']),
    roleName() {
      if (this.axios.defaults.params && this.axios.defaults.params.project_id && this.$auth.user().permissions) {
        let roleId = this.$auth.user().permissions.project_roles[this.axios.defaults.params.project_id]
        return this.$auth.user().permissions.role_names[roleId]
      }
      return 'N/A'
    },
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'toggleChat', 'switchSidebar', 'changeSidebarActive']),
    logoutFn() {
      this.$auth.logout({
        makeRequest: true,
        params: {}, // data: {} in axios
        success: function () {},
        error: function () {},
        redirect: '/login',
      })
    },
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true)
        this.changeSidebarActive(null)
      } else {
        this.switchSidebar(false)
        const paths = this.$route.fullPath.split('/')
        paths.pop()
        this.changeSidebarActive(paths.join('/'))
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar()
        this.changeSidebarActive(null)
      } else {
        this.toggleSidebar()
        const paths = this.$route.fullPath.split('/')
        paths.pop()
        this.changeSidebarActive(paths.join('/'))
      }
    },
    logout() {
      window.localStorage.setItem('authenticated', false)
      this.$router.push('/login')
    },
    runChat() {
      this.chatOpened = !this.chatOpened
    },
    runTests() {
      this.$router.push('Tests')
      this.$nextTick(() => {
        this.$set(this, 'runAllTestsTrigger', true)
      })
    },
    runDeploy(env = 'dev') {
      this.$refs['deploymentModal'].startDeployment(env)
    },
  },
}
</script>

<style src="./Header.scss" lang="scss" scoped />
