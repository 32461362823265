<template>
  <div id="wrapper">
    <!-- <div :class="{root: true, chatOpen, sidebarClose, sidebarStatic}"> -->
    <Sidebar />
    <div id="content-wrapper">
      <div class="vld-parent" :key="restCallPending">
        <Header />
      </div>
      <!-- <v-touch class="content" @swipeleft="handleSwipe" @swiperight="handleSwipe" :swipe-options="{direction: 'horizontal', threshold: 100}"> -->
      <div id="contentContainer" class="d-flex flex-column">
        <div id="content" class="container-fluid">
          <router-view />
        </div>
      </div>
      <Chat />
      <footer class="mt-auto sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Need help? <a href="mailto:support@chytrecallcentrum.cz">support@chytrecallcentrum.cz</a> </span>
            <span class="ml-3" v-if="axios.defaults && axios.defaults.params">
              Project ID: {{ axios.defaults.params.project_id }}</span
            >
          </div>
        </div>
      </footer>
      <!-- </v-touch> -->
    </div>

    <ChangePasswordModal :user="$auth.user()" :cancellable="false" ref="passwordChangeModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ChangePasswordModal from '@/components/ChangePasswordModal/ChangePasswordModal'

import Sidebar from '@/components/Sidebar/Sidebar'
import Header from '@/components/Header/Header'
import Chat from '@/components/Chat/Chat'
import './Layout.scss'

export default {
  name: 'Layout',
  components: { Sidebar, Header, Chat, ChangePasswordModal },
  methods: {
    ...mapActions('layout', ['switchSidebar', 'handleSwipe', 'changeSidebarActive']),
  },
  data() {
    return {
      loading: false,
      pendingCnt: 0,
    }
  },
  computed: {
    ...mapFields('rest', ['pending', 'settings']),
    // callsPending() {
    //   var pending = false;
    //   for (const [_, value] of Object.entries(this.$store.state.rest.pending)) {
    //     pending |= value;
    //   }
    //   return Boolean(pending);
    // },
    restCallPending() {
      // if (!this.pendingCnt) {
      //   this.loading = false;
      //   return;
      // }

      // pending with delay
      var timeout = this.pendingCnt ? 20 : 300

      setTimeout(() => {
        this.loading = Boolean(this.pendingCnt)
      }, timeout)

      // if (!this.callsPending) {
      //   this.loading = false;
      //   return;
      // }
      // setTimeout(() => {
      //   this.loading = this.callsPending;
      // }, 300);
    },
  },
  created() {
    let timeout = null
    // loading interceptors
    this.axios.interceptors.request.use(
      (config) => {
        this.pendingCnt++

        if (this.pendingCnt == 1) {
          timeout = setTimeout(() => this.$nprogress.start(), 150)
        }
        return config
      },
      (error) => {
        this.pendingCnt--
        return Promise.reject(error)
      }
    )

    this.axios.interceptors.response.use(
      (response) => {
        this.pendingCnt--

        if (this.pendingCnt == 0) {
          clearTimeout(timeout)
          this.$nprogress.done()
        }
        return response
      },
      (error) => {
        this.pendingCnt--
        return Promise.reject(error)
      }
    )

    this.$auth.apiReady().then(() => {
      if (this.$auth.user().password_expired) {
        this.$refs['passwordChangeModal'].show()
      }
    })
  },
}
</script>

<style src="./Layout.scss" lang="scss" />
